import React, { useEffect, useMemo, useState } from 'react';
import AuthorizationContext, { clearUserAuthorization } from '../contexts/authorizationContext';
import {
  clearAllCemData,
  fetchCemData,
} from '../Slices/Cem.slice';
import { useAppDispatch } from '../../app/hooks';
import { UserAuthorization, UserAuthorizationApi } from '../Types/Authorization.types';
import { showErrorSnackbar } from '../../component/snackbar/Snackbar.slice';
import { Messages } from '../../Constants/Messages';
import { fetchUserAuthorization } from '../../api/CEM/cem.v2.api';
import { UserRoles } from '../../Constants/UserRoles';
import { fetchFavoriteJobsData } from '../../pages/home/entity/FavoriteEntity.slice';

const CemAuthorization = (props: any): React.ReactElement => {
  const dispatch = useAppDispatch();

  const [userAuthorization, setUserAuthorization] = useState<UserAuthorization>(clearUserAuthorization);
  const userAuthorizationValue = useMemo(() => ({
    userAuthorization, setUserAuthorization
  }), [userAuthorization])

  useEffect(() => {
    dispatch(clearAllCemData());
    const fetchAuthorizationData = async () => {
      const userAuthorizationData = await fetchUserAuthorization();
      if (userAuthorizationData.Success) {
        let data = JSON.parse(userAuthorizationData.Data.toString()) as UserAuthorizationApi;
        let result: UserAuthorization = {
          application: {
            permissions: data.application?.map(x => x.permissions).flat(2),
            role: data.application?.map(x => x.role).flat(2),
            features: data.application?.map(x => x.features).flat(2)
          },
          client: data.client
        }
        const presenter = data.client
            .filter(x => x.role.includes(UserRoles.Presenter)).map(
                function (client) {
                  return client.id;
                }
            );
        dispatch(fetchCemData(presenter));
        dispatch(fetchFavoriteJobsData());
        setUserAuthorization(result);
      } else {
        showErrorSnackbar(
          dispatch,
          Messages.EXCEPTION_FOR_FETCHING_AUTHORIZATION
        );
        return;
      }
    }
    fetchAuthorizationData()
      .catch(() => {
        showErrorSnackbar(
          dispatch,
          Messages.EXCEPTION_FOR_FETCHING_AUTHORIZATION
        );
      });
  }, [dispatch]);

  return (
    <AuthorizationContext.Provider value={userAuthorizationValue}>
      {props.children}
    </AuthorizationContext.Provider>
  )
};
export default CemAuthorization;
