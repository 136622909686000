import { Guid } from 'guid-typescript';
import { EventLogResponse, EventType } from './Event.types';

export interface RoutedTo {
	id: number;
	status: string;
	reviewDueDate: string;
	currentlyWith: string;
	assignedTo: string;
	reviewedBy: string;
	modifiedBy: string;
	modifiedDate: string;
	isFinalized: boolean;
	comments: string;
	eventName: string;
	eventDescription: string;
}

export interface RouteToPopperFormProps {
	handleClickAway: () => void;
	handleFinalizeDialog: () =>void;	
}

export interface StatusProps {
	modifiedBy: string;
	modifiedDate: Date;
	comments: string;
	status: Status;
	eventName: string;
}

export type CurrentStatusProps = {
	statusId: number;
	currentlyWith: string;
	modifiedDate?: Date;
};

export type StatusPopperProps = {} & CurrentStatusProps;

export type StatusTrackerProps = {
	currentStatus: number;
	reviewedBy?: string;
	currentlyWith: string;
	modifiedDate: Date;
};

export interface DueDateTrackerProps {
	reviewDueDate?: Date;
	lastChange: Date;
	lastCalculated: Date;
}

export enum Status {
	Do,
	Doing,
	Done,
}

export type StatusDetailResponse = {
	id: Guid;
	itemId: string;
	status: number;
	reviewDueDate?: Date;
	currentlyWith: string;
	reviewedBy: string;
	isFinalized: boolean;
	modifiedDate: Date;
	lastChange?: Date | null;
	lastCalculated?: Date | null;
	isGreyedOut: boolean;
	hierarchyLevelId: Guid;
	latestChangeDate?: Date;
};

export interface ImportNotification {
	hierarchyLevelId: Guid;
	lastChange: Date;
	currentlyWith: string;
}

export type ReviewStatusHistoryProps = {
	eventLogs: EventLogResponse[];
};

export type CalculationSuccessfulNotificationPayload = {
	hierarchyLevelId: Guid;
	currentlyWith: string;
	lastCalculationDate: Date;
};

export type CalculationFailedNotificationPayload = {
	hierarchyLevelId: Guid;
	currentlyWith: string;
};