import { useContext, useEffect, useState } from 'react';
import { OidcIdentityContext } from '@rsmus/react-auth';
import { 
  Dialog, 
  DialogContentText, 
  Typography,
  DialogContent,
  DialogTitle, 
  Button, 
  Box,
  DialogActions 
} from '@material-ui/core';
import { 
  millisecondsToLogoutAfterWarningDialog, 
  topLine, 
  content, 
  buttonText 
} from './IdleTimeLogout.constants';

interface IdleTimeoutProps {
  handleClose: () => void
  isOpen: boolean
}

const IdleTimeoutDialog = ({
  handleClose,
  isOpen,
}: IdleTimeoutProps) => {
  const [timeId, setTimeId] = useState<any>()
  const { logout } = useContext(OidcIdentityContext)

  useEffect(() => {
    if (isOpen) {
      const timeoutId = setTimeout(() => {
        if (isOpen) {
          handleClose()
          logout(true)
        }
      }, millisecondsToLogoutAfterWarningDialog)
      setTimeId(timeoutId)
    }
  }, [isOpen])

  const onContinueClick = () => {
    clearTimeout(timeId)
    handleClose()
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onContinueClick}
      disableRestoreFocus={true}
    >
      <DialogTitle>
        <Typography variant="h6">{topLine}</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Box>
            {content}
          </Box>
        </DialogContentText> 
      </DialogContent>
      <DialogActions>
        <Button onClick={onContinueClick} color="primary">
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default IdleTimeoutDialog;
