import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BreadcrumbNavigationDetails } from './BreadcrumbNavigationDetails.types';

const breadcrumbDetails: BreadcrumbNavigationDetails = {
};

const initialState = {
    breadcrumbDetails: breadcrumbDetails
}

const breadcrumbNavigationSlice = createSlice({
    name: 'breadcrumbNavigation',
    initialState,
    reducers: {
        setBreadcrumbNavigationDetails(
            state,
            action: PayloadAction<BreadcrumbNavigationDetails>
        ): void {
            state.breadcrumbDetails = action.payload;
        }
    } 
});

export const { setBreadcrumbNavigationDetails } = breadcrumbNavigationSlice.actions;

export default breadcrumbNavigationSlice.reducer;