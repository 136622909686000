import { IconGroup, StatusWidgetMessage } from '../Types/Notifications.types';
import { AppDispatch } from '../../app/store';
import { addNotification, addUserNotification } from '../Slices/Notifications.slice';
import { setStatusUpdateNotificationStatusWidgetState } from '../../component/status-widget/statusWidget.slice';
import { GetStatusWidgetEventMessage } from '../../Constants/StatusWidgetMessages';
import { showErrorSnackbar, showSuccessSnackbar } from '../../component/snackbar/Snackbar.slice';
import { EventType } from '../../component/status-widget/types/Event.types';

export function addClientStatusWidgetNotification(
    statusWidgetMessage: StatusWidgetMessage,
    dispatch: AppDispatch,
    userManager: any,
) {
    const isSameUser = userManager.user?.profile?.preferred_username.toLowerCase() === statusWidgetMessage.reviewedByEmail.toLowerCase();
    const message = GetStatusWidgetEventMessage(statusWidgetMessage.eventType);
    dispatch(
        addNotification({
            type: IconGroup.StatusWidget,
            user: statusWidgetMessage.reviewedBy,
            dateTime: new Date().toString(),
            message: message,
            groupId: statusWidgetMessage.groupId,
        }),
    );
    dispatch(setStatusUpdateNotificationStatusWidgetState(statusWidgetMessage));
    if ((statusWidgetMessage.eventType === EventType.JobLockFinalizedFail
        || statusWidgetMessage.eventType === EventType.JobUnlockFail) && isSameUser) {
            showErrorSnackbar(dispatch, message);
        return;
    }
    isSameUser && showSuccessSnackbar(dispatch, message);
}

export function addUserStatusWidgetNotification(
    statusWidgetMessage: StatusWidgetMessage,
    dispatch: AppDispatch,
) {
    dispatch(
        addUserNotification({
            type: IconGroup.StatusWidget,
            user: statusWidgetMessage.reviewedBy,
            dateTime: new Date().toString(),
            message: GetStatusWidgetEventMessage(statusWidgetMessage.eventType),
            groupId: statusWidgetMessage.groupId,
        }),
    );
    dispatch(setStatusUpdateNotificationStatusWidgetState(statusWidgetMessage));
}