export enum EventType {
    UploadImportTemplateSuccess,
    UploadImportTemplateFail,
    InitiateUploadImportTemplate,
    UpdateSettings,
    UploadClientRuleSet,
    UploadRsmRuleSet,
    UploadClientAssetsOfInterest,
    UploadRsmAssetsOfInterest,
    UploadClientReferenceTables,
    UploadRsmReferenceTables,
    CalculateSuccess,
    CalculateFail,
    RouteToUserAssign,
    RouteToUserReject,
    RouteToUserApprove,
    RouteToCoEAssign,
    RouteToCoEReject,
    RouteToCoEApprove,
    JobLockedFinalized,
    JobUnlocked,
    CalculationInitiated,
    JobLockFinalizedFail,
    JobUnlockFail
}


export enum EventTypeDisplayString {
    Assigned = 'Assigned',
    Rejected = 'Rejected',
    Approved = 'Approved',
    Finalized = 'Finalized',
    Unlocked = 'Unlocked'
}

export const EventTypeDisplay = new Map<number, string>([
    [EventType.RouteToUserAssign, EventTypeDisplayString.Assigned],
    [EventType.RouteToUserReject, EventTypeDisplayString.Rejected],
    [EventType.RouteToUserApprove, EventTypeDisplayString.Approved],
    [EventType.JobLockedFinalized, EventTypeDisplayString.Finalized],
    [EventType.JobUnlocked, EventTypeDisplayString.Unlocked]
])

export interface EventCreateRequest {
    eventId: number;
    comments?: string;
    assignedTo: string;
    assignedToEmail: string;
    reviewDueDate: Date;
    lastCalculated?: Date;
    lastChange?: Date;
}

export interface EventLogResponse {
    eventType: EventType;
    dueDate: Date;
    comments: string;
    assignedTo: string;
    assignedToEmail: string;
    modifiedBy: string;
    modifiedDate: Date;
}