import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Guid } from 'guid-typescript';
import { fetchSettings } from '../../../api/Settings/Settings.api';
import { AppDispatch, RootState } from '../../../app/store';
import { convertJsonToLibrariesLibraryValuePascalCase } from '../../../component/Settings/Converters';
import { Messages } from '../../../Constants/Messages';
import { showErrorSnackbar } from '../../../component/snackbar/Snackbar.slice'
import { LibraryType, RetriveEnrichmentSettings, WarehouseData } from '../../../component/Settings/Settings.types';
import { ResponseData } from '../../../api/ApiFetch/ApiResponse';

interface State {
    referenceData: string;
    rulesetsData: string;
    assetWarehouseData: string,
    dividendWarehouseData: string,
}

const initialState: State = {
    referenceData: Guid.EMPTY,
    rulesetsData: Guid.EMPTY,
    assetWarehouseData: Guid.EMPTY,
    dividendWarehouseData: Guid.EMPTY
};

export const fetchSettingsData = createAsyncThunk<
    void,
    Guid,
    {
        dispatch: AppDispatch;
        state: RootState;
    }
>('settingsData/fetchSettingsData', async (jobId, thunkAPI) => {
    await fetchSettings(jobId).then(function (result: ResponseData) {
        if (!result.Success) {
            showErrorSnackbar(thunkAPI.dispatch, Messages.EXCEPTION_FOR_FETCH);
            return;
        }
        const settingsData = convertJsonToLibrariesLibraryValuePascalCase(result);
        thunkAPI.dispatch(
            resetInputData()
        );
        settingsData?.forEach(function (value: RetriveEnrichmentSettings) {
            switch (value.LibraryType) {
                case LibraryType.ClientReferenceLibrary:
                    thunkAPI.dispatch(
                        setReferenceData(value.LibraryValue)
                    );
                    break;
                case LibraryType.Rulesets:
                    thunkAPI.dispatch(
                        setRulesetsData(value.LibraryValue)
                    );
                    break;
                case LibraryType.AssetMasterWarehouses:
                    const assetMasterWarehouse = JSON.parse(value.LibraryValue) as WarehouseData;
                    thunkAPI.dispatch(
                        setAssetWarehouseData(assetMasterWarehouse.WarehousesId.toString())
                    );
                    break;
                case LibraryType.DividendWarehouses:
                    const dividendWarehouse = JSON.parse(value.LibraryValue) as WarehouseData;
                    thunkAPI.dispatch(
                        setDividendWarehouseData(dividendWarehouse.WarehousesId.toString())
                    );
                    break;
            }
        });
    });
});

const inputDetailsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        setReferenceData: (
            state,
            action: PayloadAction<string>
        ) => {
            state.referenceData = action.payload;
        },
        setRulesetsData: (
            state,
            action: PayloadAction<string>
        ) => {
            state.rulesetsData = action.payload;
        },
        setAssetWarehouseData: (
            state,
            action: PayloadAction<string>
        ) => {
            state.assetWarehouseData = action.payload;
        },
        setDividendWarehouseData: (
            state,
            action: PayloadAction<string>
        ) => {
            state.dividendWarehouseData = action.payload;
        },
        resetInputData: (
            state,
        ) => {
            state.dividendWarehouseData = Guid.EMPTY.toString();
            state.assetWarehouseData = Guid.EMPTY.toString();
            state.rulesetsData = Guid.EMPTY.toString();
            state.referenceData = Guid.EMPTY.toString();
        },
    },
});

export const { setReferenceData, setRulesetsData, setAssetWarehouseData, setDividendWarehouseData, resetInputData } = inputDetailsSlice.actions;

export default inputDetailsSlice.reducer;