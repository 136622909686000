import React from 'react';

interface SvgProps {
	svg: string;
	className?: string;
	title?: string;
	onClick?: () => void;
}

const SvgIcon = (props: SvgProps): React.ReactElement => {
	const { svg, className, title, onClick } = props;
	return (
		<div
			title={title}
			className={className}
			onClick={onClick}
			dangerouslySetInnerHTML={{
				__html: svg,
			}}
		></div>
	);
};

export default SvgIcon;
