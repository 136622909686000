import { createContext } from 'react';
import { UserAuthorization } from '../Types/Authorization.types';

export const clearUserAuthorization = {} as UserAuthorization;

const AuthorizationContext = createContext({
  userAuthorization: clearUserAuthorization,
  setUserAuthorization: (userAuthorization: UserAuthorization) => {}
});

export default AuthorizationContext;
