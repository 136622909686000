import { Guid } from 'guid-typescript';
import { Fetch } from '../ApiFetch/ApiFetch';
import { HTTPMethod } from '../ApiFetch/HTTPMethod.types';
import { envProperties } from '../../core/Utils/EnvProperties';
import { CreateTextResponse, ResponseData } from '../ApiFetch/ApiResponse';

const cemBaseUrl = `${envProperties.CemBaseUrl}v2`;

export const fetchUserList = async (engagementId: Guid): Promise<ResponseData> => {
    const formattedUrl = `${cemBaseUrl}/engagements/${engagementId}/users?$filter=ApplicationId eq '${envProperties.authentication.clientId}'`;
    const response = await Fetch({
        url: formattedUrl,
        method: HTTPMethod.Get
    }).catch((error) => {
        return error;
    });
    return CreateTextResponse(response);
};

export const fetchClients = async (): Promise<ResponseData> => {
    const response = await Fetch({
        url: `${cemBaseUrl}/clients?$filter=ApplicationId eq '${envProperties.authentication.clientId}'`,
        method: HTTPMethod.Get
    }).catch((error) => {
        return error;
    });
    return CreateTextResponse(response);
};

export const fetchEngagements = async (mdmClientId: number): Promise<ResponseData> => {
    const response = await Fetch({
        url: `${cemBaseUrl}/engagements?$filter=MdmClientId eq '${mdmClientId}'`,
        method: HTTPMethod.Get
    }).catch((error) => {
        return error;
    });
    return CreateTextResponse(response);
};

export const fetchProjects = async (mdmClientId: number): Promise<ResponseData> => {
    const response = await Fetch({
        url: `${cemBaseUrl}/projects?$filter=MdmClientId eq '${mdmClientId}'`,
        method: HTTPMethod.Get
    }).catch((error) => {
        return error;
    });
    return CreateTextResponse(response);
};

export const fetchJobs = async (clientId: number): Promise<ResponseData> => {
    const response = await Fetch({
        url: `${cemBaseUrl}/jobs?$filter=mdmclientid eq '${clientId}'`,
        method: HTTPMethod.Get
    }).catch((error) => {
        return error;
    });
    return CreateTextResponse(response);
};

export const fetchUserAuthorization = async (): Promise<ResponseData> => {
    const response = await Fetch({
        url: `${envProperties.CemBaseUrl}v3/Authorization/User`,
        method: HTTPMethod.Get
    }).catch((error) => {
        return error;
    });
    return CreateTextResponse(response);
};