import { Guid } from 'guid-typescript';

export interface CalculationStatusResponse {
	calculationId?: Guid;
	endTime?: Date;
	jobId?: Guid;
	startTime: Date;
	status: CalculationStepStatus;
}

export type CalculationStatusResponseV2 = {
	lastFailedCalculationRun: CalculationStatusResponse;
	lastSuccessfulCalculationRun: CalculationStatusResponse;
	mostRecentCalculationRun: CalculationStatusResponse;
};

export type CalculationRunResponse = {
	endTime?: Date;
	startTime: Date;
	status: CalculationStepStatus;
	importId: Guid;
	statusDisplay: string;
	calculationId: Guid;
	errorStep? : CalculationError;
	errorSubStep : string;
};

export enum CalculationStepStatus {
	NotStarted = 0,
	Enriching = 1,
	Calculating = 2,
	ReadyForPackaging = 3,
	Complete = 4,
	Downloaded = 5,
	PackagingFailed = 6,
	Failed = 7,
	TimedOut = 8,
}

export interface DownloadCalculationReports {
	calculationId: string;
	jobId: Guid;
}

export enum CalculationError
{
	Organizer= 'Organizer',
	Worker = 'Worker',
	Enrichment = 'Enrichment',
	Timeout = 'Timeout',
	Neo4J = 'Neo4J',
	Orchestrator = 'Orchestrator',
	Unhandled = 'Unhandled'
}