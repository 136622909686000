import { Guid } from 'guid-typescript';
import { Messages } from '../../Constants/Messages';

export interface DatasetDetailsResponse {
	datasetId: Guid;
	datasetName: string;
	importId: Guid;
	status: JobFileImportStatus;
	hasWarning?: boolean;
	hasError?: boolean;
}

export enum JobFileImportStatus {
	Processing = 6,
	Complete = 126,
}

export enum WarningLevel {
	Error= 'Error'
}

export interface Warning {
	WarningLevel: string;
}

export const GetDisplayMessageForImportStatus = (status: JobFileImportStatus): string => {
	var displayMessages: { [status: number]: string } = {};
	displayMessages[JobFileImportStatus.Processing] = Messages.FILE_IMPORT_IN_PROGRESS_MESSAGE;
	displayMessages[JobFileImportStatus.Complete] = Messages.UPLOADED_SUCCESSFULLY;

	return displayMessages[status];
};
