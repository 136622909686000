export enum HomeView {
	Favorites,
	TeamExplorer,
}

export interface TeamDataset {
	TeamId: string;
	TeamName: string;
	TeamDescription: string;
	DatasetId: string;
	Name: string;
	Description: string;
	Application: string;
	TaxYear: number;
	FormProductionDatasetId: string;
}

export interface Client {
	ClientId: string;
	ClientName: string;
	Description: string;
	Created: string;
}

export interface DatasetViewModel {
	TeamId: string;
	DatasetId: string;
	Name: string;
	Description: string;
	CreationTime: string;
	TeamName: string;
	TeamDescription: string;
	Application: string;
	TaxYear: number;
	FormProductionDatasetId: string;
	ParentDatasetId: string;
	IsTemporary: boolean;
	IsTrackingHistory: boolean;
	CreatedBy: string;
}

export const emptyDatasetViewModel: DatasetViewModel = {
	TeamId: '',
	DatasetId: '',
	Name: '',
	Description: '',
	CreationTime: '',
	TeamName: '',
	TeamDescription: '',
	Application: '',
	TaxYear: 0,
	FormProductionDatasetId: '',
	ParentDatasetId: '',
	IsTemporary: false,
	IsTrackingHistory: false,
	CreatedBy: '',
} as const;

export interface DatasetDetailData {
	key: string;
	data: {
		Name: string;
		Description: string;
		TeamId: string;
		DatasetId: string;
		TaxYear: number;
		FormProductionDatasetId: string;
		Application: string;
	};
}

export enum Jobs {
	AllJobs = 0,
	FavoriteJobs = 1
}

export interface ClientModel{
	name: string,
	mdmClientId: number
}