import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CalculationStatusResponse, CalculationStatusResponseV2 } from '../../core/Types/Calculation.types';
import { Guid } from 'guid-typescript';
import { fetchMostRecentCalculationRuns, lastCalculationRun } from '../../api/calculation/calculationController-api';
import { AppDispatch, RootState } from '../../app/store';
import { checkCalculationInProgress } from '../../core/Utils/utils';
import StatusCode from 'status-code-enum';
import { handleGetStatusDetail } from '../status-widget/statusWidget.slice';

type State = {
	isCalculationHistoryDialogVisible: boolean;
	mostRecentCalcRun: CalculationStatusResponse;
	latestSuccessfulCalcRun?: CalculationStatusResponse;
	latestFailedCalcRun: CalculationStatusResponse;
	isCalculationInProgress: boolean;
};

const initialState: State = {
	isCalculationHistoryDialogVisible: false,
	mostRecentCalcRun: {} as CalculationStatusResponse,
	latestFailedCalcRun: {} as CalculationStatusResponse,
	isCalculationInProgress: false
};

export const handleSetRecentCompletedCalcRunState = createAsyncThunk<
	void,
	Guid,
	{
		dispatch: AppDispatch;
		state: RootState;
	}
>('calculation/setMostRecentCalcRunState', async (jobId, thunkAPI) => {
	const recentCalculationRunResponse = await fetchMostRecentCalculationRuns(jobId);
	const response = JSON.parse(recentCalculationRunResponse.Data.toString()) as CalculationStatusResponseV2;
	thunkAPI.dispatch(setCalculationInProgress(false));
	thunkAPI.dispatch(completedCalculationRunDetails(response));
	thunkAPI.dispatch(handleGetStatusDetail(jobId));
});

export const setRecentCalcRunState = createAsyncThunk<
	void,
	Guid,
	{
		dispatch: AppDispatch;
		state: RootState;
	}
>('calculation/setRecentCalcRunState', async (jobId, thunkAPI) => {
	const recentCalculationRunResponse = await fetchMostRecentCalculationRuns(jobId);
	const response = JSON.parse(recentCalculationRunResponse.Data.toString()) as CalculationStatusResponseV2;
	thunkAPI.dispatch(completedCalculationRunDetails(response));
});

export const handleSetRecentCalcRunState = createAsyncThunk<
	void,
	Guid,
	{
		dispatch: AppDispatch;
		state: RootState;
	}
>('calculation/setMostRecentCalcRunState', async (jobId, thunkAPI) => {
	const recentCalculationRun = await lastCalculationRun(jobId);

	if (recentCalculationRun.Success && recentCalculationRun.StatusCode === StatusCode.SuccessNoContent) {
		thunkAPI.dispatch(setCalculationInProgress(false));
		return;
	}
	if (recentCalculationRun.Success && recentCalculationRun.StatusCode === StatusCode.SuccessOK) {
		const lastCalcRun = JSON.parse(recentCalculationRun.Data.toString()) as CalculationStatusResponse;
		thunkAPI.dispatch(setCalculationInProgress(checkCalculationInProgress(lastCalcRun.status)));
		return;
	}
	thunkAPI.dispatch(setCalculationInProgress(false));
});

const calculationRunSlice = createSlice({
	name: 'calculationRun',
	initialState,
	reducers: {
		calculationHistoryDialogOpened(state): State {
			return {
				...state,
				isCalculationHistoryDialogVisible : true,
				mostRecentCalcRun: state.mostRecentCalcRun as CalculationStatusResponse,
				latestSuccessfulCalcRun: state.latestSuccessfulCalcRun as CalculationStatusResponse,
				latestFailedCalcRun: state.latestFailedCalcRun as CalculationStatusResponse,
			};
		},
		calculationHistoryDialogClosed(state): State {
			return {
				...state,
				isCalculationHistoryDialogVisible : false,
				mostRecentCalcRun: state.mostRecentCalcRun as CalculationStatusResponse,
				latestSuccessfulCalcRun: state.latestSuccessfulCalcRun as CalculationStatusResponse,
				latestFailedCalcRun: state.latestFailedCalcRun as CalculationStatusResponse,
			};
		},
		completedCalculationRunDetails(state, action: PayloadAction<CalculationStatusResponseV2>): State {
			const { lastFailedCalculationRun, lastSuccessfulCalculationRun, mostRecentCalculationRun } = action.payload;
			return {
				...state,
				mostRecentCalcRun: mostRecentCalculationRun,
				latestSuccessfulCalcRun: lastSuccessfulCalculationRun,
				latestFailedCalcRun: lastFailedCalculationRun,
			};
		},
		setCalculationInProgress(state, action: PayloadAction<boolean>): State {
			return {
				...state,
				isCalculationInProgress: action.payload,
				mostRecentCalcRun: state.mostRecentCalcRun as CalculationStatusResponse,
				latestSuccessfulCalcRun: state.latestSuccessfulCalcRun as CalculationStatusResponse,
				latestFailedCalcRun: state.latestFailedCalcRun as CalculationStatusResponse,
			};
		},
		resetLastCalculationRunDetails(): State {
			return {
				...initialState,
			};
		},
	},
});

export const {
	calculationHistoryDialogOpened,
	calculationHistoryDialogClosed,
	completedCalculationRunDetails,
	setCalculationInProgress,
	resetLastCalculationRunDetails,
} = calculationRunSlice.actions;

export default calculationRunSlice.reducer;
