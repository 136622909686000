const ENV = (window as any)?._env || process.env;
export const getEnvVariable = (name: string) => ENV[name] || process.env[name];
export const envProperties = {
	StatusWidgetApiUrl: getEnvVariable('REACT_APP_STATUS_WIDGET_URL'),
	ImportApiUrl: getEnvVariable('REACT_APP_IMPORT_URL'),
	EnrichmentApiUrl: getEnvVariable('REACT_APP_ENRICHMENT_URL'),
	CalculationApiUrl: getEnvVariable('REACT_APP_CALCULATION_URL'),
	CommonApiUrl: getEnvVariable('REACT_APP_COMMON_URL'),
	WebBaseUrl: getEnvVariable('REACT_APP_WEBBASE_URL'),
	CemBaseUrl: getEnvVariable('REACT_APP_CEMBASE_URL'),
	SignalRUrl: getEnvVariable('REACT_APP_SIGNALR_URL'),
	IsDevToolsEnabled: getEnvVariable('REACT_APP_ENABLE_DEV_TOOLS'),
	EulaApiUrl: getEnvVariable('REACT_APP_EULABASE_URL'),
	authentication: {
		clientId: getEnvVariable('REACT_APP_AUTH_CLIENTID'),
		authority: getEnvVariable('REACT_APP_AUTH_AUTHORITY'),
	},
	retryPolicyCount: getEnvVariable('REACT_APP_RETRY_POLICY_COUNT'),
};
