import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getClientReferenceLibrary } from '../../../../api/ReferenceTable/ClientReferenceTableImport.api';
import { getRsmReferenceLibrary } from '../../../../api/ReferenceTable/RsmReferenceTableImport.api';
import { AppDispatch, RootState } from '../../../../app/store';
import { Messages } from '../../../../Constants/Messages';
import { AssetOfInterestInput } from '../../assetsOfInterest/AssetOfInterest.types';
import { showErrorSnackbar } from '../../../../component/snackbar/Snackbar.slice';
import { convertJsonToPascalCase } from '../../../../component/Settings/Converters';
import { getDisplayStatusName } from '../../../../core/Utils/utils';

interface State {
	clientReferenceData: AssetOfInterestInput[];
	rsmReferenceData: AssetOfInterestInput[];
	isReferenceFileUploaded: Boolean;
}

const initialState: State = {
	clientReferenceData: [],
	rsmReferenceData: [],
	isReferenceFileUploaded: false,
};

export const fetchClientReferenceData = createAsyncThunk<
	void,
	string,
	{
		dispatch: AppDispatch;
		state: RootState;
	}
>('referenceData/fetchClientReferenceData', async (clientId, thunkAPI) => {
	await getClientReferenceLibrary(clientId).then(function (result) {
		if (!result.Success) {
			showErrorSnackbar(thunkAPI.dispatch, Messages.EXCEPTION_FOR_FETCH);
			return;
		}
		const jsonData = convertJsonToPascalCase(result);
		let data = JSON.parse(jsonData) as AssetOfInterestInput[];
		data.map(function (info) {
			info.ProvidedByRSM = false;
			info.Status = getDisplayStatusName(info.ImportStatus);
			return info;
		});
		thunkAPI.dispatch(setClientReferenceData(data));
	});
});

export const fetchRSMReferenceData = createAsyncThunk<
	void,
	void,
	{
		dispatch: AppDispatch;
		state: RootState;
	}
>('referenceData/fetchRSMReferenceData', async (_: void, thunkAPI) => {
	await getRsmReferenceLibrary().then(function (result) {
		if (result.Success) {
			const jsonData = convertJsonToPascalCase(result);
			let data = JSON.parse(jsonData) as AssetOfInterestInput[];
			data.map(function (info) {
				info.ProvidedByRSM = true;
				info.Status = getDisplayStatusName(info.ImportStatus);
				return info;
			});
			thunkAPI.dispatch(setRSMReferenceData(data));
			return;
		}
		showErrorSnackbar(thunkAPI.dispatch, Messages.EXCEPTION_FOR_FETCH);
	});
});

const referenceTableDataSlice = createSlice({
	name: 'referenceTable',
	initialState,
	reducers: {
		setClientReferenceData: (state, action: PayloadAction<AssetOfInterestInput[]>) => {
			state.clientReferenceData = action.payload;
		},
		setRSMReferenceData: (state, action: PayloadAction<AssetOfInterestInput[]>) => {
			state.rsmReferenceData = action.payload;
		},
		setReferenceFileUpload: (state, action: PayloadAction<Boolean>) => {
			state.isReferenceFileUploaded = action.payload;
		},
	},
});

export const { setClientReferenceData, setRSMReferenceData, setReferenceFileUpload } = referenceTableDataSlice.actions;
export default referenceTableDataSlice.reducer;
