import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from '../../app/store';
import { SnackbarMessage, SnackbarType } from './Snackbar.types';

type State = {
    isSnackbarOpen: boolean;
    snackbarQueue: SnackbarMessage[];
    snackbarMessage: SnackbarMessage;
};

const LONG_DURATION = 5000;

const INITIAL_MESSAGE = {message: '' ,type: SnackbarType.Info, duration: LONG_DURATION, key : 0};

const initialState: State = {
    isSnackbarOpen: false,
    snackbarQueue: [],
    snackbarMessage: INITIAL_MESSAGE
};


const processSnackbarQueueImpl = (state: State): State => {
    if (state.snackbarQueue.length) {
        const localSnackbarQueue = [...state.snackbarQueue];
        return {...state, isSnackbarOpen: true, snackbarMessage: localSnackbarQueue.shift() as SnackbarMessage, snackbarQueue: localSnackbarQueue };
    }
    return {...initialState};
};

const snackbarSlice = createSlice({
    name: 'snackbar',
    initialState,
    reducers: {
        closeSnackbar(state): State {
            return { ...state, isSnackbarOpen: false };
        },
        addSnackbar(state, action: PayloadAction<SnackbarMessage>): State {
            const localSnackbarQueue = [...state.snackbarQueue];
            localSnackbarQueue.push(action.payload);
            if (state.isSnackbarOpen) {
                return {...state, snackbarQueue: localSnackbarQueue };
            } else {
                return {...state, isSnackbarOpen: true, snackbarMessage: localSnackbarQueue.shift() as SnackbarMessage, snackbarQueue: localSnackbarQueue };
            }
        },
        exitSnackbar(state): State {
            return processSnackbarQueueImpl(state);
        }
    }
});

export const {
    addSnackbar,
    closeSnackbar,
    exitSnackbar
} = snackbarSlice.actions;

export const showLongInfoSnackbar = (
    dispatch: AppDispatch,
    message: string
): void => {
    dispatch(
        addSnackbar({
            message,
            type: SnackbarType.Info,
            duration: LONG_DURATION,
            key: new Date().getTime()
        })
    );
};

export const showSuccessSnackbar = (
    dispatch: AppDispatch,
    message: string
): void => {
    dispatch(
        addSnackbar({
            message,
            type: SnackbarType.Success,
            duration: LONG_DURATION,
            key: new Date().getTime()
        })
    );
};

export const showWarningSnackbar = (
    dispatch: AppDispatch,
    message: string
): void => {
    dispatch(
        addSnackbar({
            message,
            type: SnackbarType.Warning,
            duration: LONG_DURATION,
            key: new Date().getTime()
        })
    );
};

export const showErrorSnackbar = (
    dispatch: AppDispatch,
    message: string
): void => {
    dispatch(
        addSnackbar({
            message,
            type: SnackbarType.Error,
            duration: LONG_DURATION,
            key: new Date().getTime()
        })
    );
};

export default snackbarSlice.reducer;
