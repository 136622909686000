import { FetchApi } from '../ApiFetch/ApiFetch';
import { HTTPMethod } from '../ApiFetch/HTTPMethod.types';
import { ServiceType } from '../ApiFetch/ServiceType.types';
import { Guid } from 'guid-typescript';
import { ProcessId } from '../../core/Types/ProcessId.types';
import { CreateBlobResponse } from '../ApiFetch/ApiResponse';
import { ApiUrls } from '../ApiConstants/ApiUrls';

export async function downloadEmptyTemplate(jobId: any) {
	const response = await FetchApi({
		endpoint: ApiUrls.DOWNLOAD_EMPTY_TEMPLATE.replace('{jobId}', jobId),
		method: HTTPMethod.Get,
		apiType: ServiceType.Import,
	}).catch((error) => {
		return error;
	});
	return CreateBlobResponse(response);
}

export async function downloadActiveDataset(jobId: Guid, datasetId?: Guid) {
	const response = await FetchApi({
		method: HTTPMethod.Get,
		endpoint: ApiUrls.DOWNLOAD_ACTIVE_TEMPLATE.replace('{jobId}', jobId.toString())
					.replace('{datasetId}', datasetId?.toString() ?? ''),
		apiType: ServiceType.Import,
	}).catch((error) => {
		return error;
	});
	return CreateBlobResponse(response);
}

export async function downloadReports(processId: ProcessId) {
	const response = await FetchApi({
		method: HTTPMethod.Get,
		endpoint: ApiUrls.DOWNLOAD_REPORTS.replace('{clientId}', processId.clientId)
					.replace('{jobId}', processId.jobId.toString())
					.replace('{importId}', processId.importId?.toString() ?? 'undefined'),
		apiType: ServiceType.Calculation,
	}).catch((error) => {
		return error;
	});
	return CreateBlobResponse(response);
}

export async function downloadFile(fileId: string) {
	const url = ApiUrls.DOWNLOAD_FILE.replace('{fileId}', fileId);
	const response = await FetchApi({
		endpoint: url,
		method: HTTPMethod.Get,
		apiType: ServiceType.Enrichment,
	}).catch((error) => {
		return error;
	});
	return CreateBlobResponse(response);
}

export async function downloadFileFromJob(fileId: string, jobId: string) {
	const url = ApiUrls.DOWNLOAD_FILE_FROM_JOB.replace('{jobId}', jobId)
				.replace('{fileId}', fileId);
	const response = await FetchApi({
		endpoint: url,
		method: HTTPMethod.Get,
		apiType: ServiceType.Enrichment,
	}).catch((error) => {
		return error;
	});
	return CreateBlobResponse(response);
}

export async function downloadReportByCalculationId(calculationId: string, jobId: string) {
	const response = await FetchApi({
		method: HTTPMethod.Get,
		endpoint: ApiUrls.DOWNLOAD_REPORT.replace('{calculationId}', calculationId)
					.replace('{jobId}', jobId),
		apiType: ServiceType.Calculation,
		apiVersion: 2,
	}).catch((error) => {
		return error;
	});
	return CreateBlobResponse(response);
}

export async function downloadErrorByCalculationId(calculationId: string, jobId: string) {
	const response = await FetchApi({
		method: HTTPMethod.Get,
		endpoint: ApiUrls.DOWNLOAD_ERROR.replace('{calculationId}', calculationId)
					.replace('{jobId}', jobId),
		apiType: ServiceType.Calculation,
		apiVersion: 2,
	}).catch((error) => {
		return error;
	});
	return CreateBlobResponse(response);
}