import { useCallback, useEffect, useState } from 'react';
import IdleTimeLogoutDialog from './IdleTimeLogoutDialog';
import { idleTimeToWarningDialogInMilliseconds } from './IdleTimeLogout.constants';
 
const pageEventsPreventingIdleState = [
  'mousemove',
  'keydown',
  'wheel',
  'DOMMouseScroll',
  'mousewheel',
  'mousedown',
  'touchstart',
  'touchmove',
  'MSPointerDown',
  'MSPointerMove',
  'visibilitychange',
]

/* If we define this in a component state, we will run into an infinite loop situation. */
let timerId: any = 0

const IdleTimeLogout = () => {
  const [isDialogOpen, setDialogOpen] = useState<boolean>(false);

  const resetTime = useCallback(() => {
    clearTimeout(timerId)
      const id = setTimeout(() => {
        setDialogOpen(true) 
        resetTime()
      }, idleTimeToWarningDialogInMilliseconds)
      timerId = id 
    }
  , [])

  useEffect(() => {
    pageEventsPreventingIdleState.forEach((event) => document.addEventListener(event, resetTime))
      return () => {
        pageEventsPreventingIdleState.forEach((event) =>
          document.removeEventListener(event, resetTime)
        )
      }
  }, [resetTime])

  useEffect(() => {
    resetTime()
  }, [])

  const handleClose = () => {
    setDialogOpen(false)
  }

  return (
    <IdleTimeLogoutDialog
      isOpen={isDialogOpen}
      handleClose={handleClose}
    />
  )
}

export default IdleTimeLogout;
