import { Messages } from '../../Constants/Messages';
import { DatasetDetailsResponse, JobFileImportStatus } from '../Types/DatasetDetailsResponse.types';

export const conditionalCalculationTooltips = (dataset: DatasetDetailsResponse, calculationInProgress: boolean) => {
	if (calculationInProgress) {
		return Messages.CALCULATIONS_IN_PROGRESS;
	}
	if (Object.keys(dataset).length === 0) {
		return Messages.BLOCK_CALC_RUN_NO_DATASET;
	} else if (dataset.status === JobFileImportStatus.Processing) {
		return Messages.BLOCK_CALC_RUN_IMPORT_IN_PROGRESS;
	} else if (dataset.status % 2 === 1) {
		return Messages.BLOCK_CALC_RUN_IMPORT_FAILED;
	} else if (dataset.hasError) {
		return Messages.ERROR_IN_DATASET_CALCULATION;
	} else {
		return Messages.START_CALCULATION;
	}
};

export const conditionalImportStatus = (dataset: DatasetDetailsResponse) => {
	if (
		Object.keys(dataset).length === 0 ||
		dataset.status === JobFileImportStatus.Processing ||
		dataset.status % 2 === 1
	) {
		return false;
	} else {
		return true;
	}
};
