import { EventType } from '../../component/status-widget/types/Event.types';
import { Status } from '../../component/status-widget/types/StatusTracker.types';

export interface Notification {
	message: string;
	user: string;
	dateTime: string;
	type: IconGroup;
	groupId: string;
	id?: string;
}

export type CalculationFailureMessage = {
	groupId: string;
	calculationId: string;
	startedBy: string;
	completedOn: string;
};

export type CalculationInitiationMessage = {
	groupId: string;
	calculationId: string;
	startedBy: string;
	startedOn: string;
};

export type CalculationSuccessMessage = CalculationFailureMessage & {
	reportLinks: string[];
};

export type ImportMessage = {
	groupId: string;
	importId: string;
	importedBy: string;
	importedOn: string;
};
export type SettingsNotification = {
	groupId: string;
	type: SettingsType;
	startedBy: string;
	completedOn: string;
};

export type ClientReferenceUploadMessage = {
	groupId: string;
	fileName: string;
	uploadedBy: string;
	uploadedOn: string;
};

export enum IconGroup {
	Calculation,
	Import,
	ClientReference,
	ImportSuccessWithWarning,
	Settings,
	StatusWidget
}

export type SignalRImportMessage = {
	signalrHubMethods: Function;
	importMessage: ImportMessage;
	notificationType: NotificationType;
};

export enum NotificationType {
	Group,
	User,
}
export enum SettingsType {
	Analysis = 0,
	Library = 1,
}

export type StatusWidgetMessage = {
	groupId: string;
	reviewedBy: string;
	eventType: EventType;
	status: Status;
	reviewedByEmail: string;
	assignedTo: string;
};
