import { Guid } from 'guid-typescript';
import { useContext, useEffect } from 'react';
import { batch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import { fetchSettingsData } from '../../pages/input/inputDetails/InputDetails.slice';
import { createHubConnection, hubConnection, startHub } from '../signalr/trade-signalr';
import {
	clientSelected, fetchUserListData, jobSelected, projectSelected,
} from '../Slices/Cem.slice';
import { fetchDatasetDetails } from '../Slices/Dataset.slice';
import { joinGroup } from '../Slices/Notifications.slice';
import { deepLinkingTimeOut, pageNotFoundPath } from '../Utils/constants';
import DeepLinkingContext from './DeepLinkingContext';
import { envProperties } from '../Utils/EnvProperties';
import { OidcIdentityContext } from '@rsmus/react-auth';

const DeepLinkingContextProvider = ({ children }: any) => {
	const dispatch = useAppDispatch();
	const history = useHistory();
	let retryCounter: number;

	const jobList = useAppSelector((state: RootState) => state.cem.jobs);
	const projectList = useAppSelector((state: RootState) => state.cem.projects);
	const clientList = useAppSelector((state: RootState) => state.cem.clients);
	const isJob = jobList.length > 0 && clientList.length > 0 && projectList.length > 0;
    const userManager = useContext(OidcIdentityContext);

	const handleDeepLinkingOrRedirectHome = (jobId: string) => {
		if (isJob) {
			if (jobId) {
				handleDeepLinkingURL(jobId);
			} else {
				history.push(window.location.pathname);
			}
		} else {
			setTimeout(() => {
				if (retryCounter < envProperties.retryPolicyCount) {
					handleDeepLinkingOrRedirectHome(jobId);
				}
			}, deepLinkingTimeOut);
			retryCounter++;
		}
	};

	useEffect(() => {
		if (!hubConnection) {
			const hubConnection = createHubConnection();
			startHub(hubConnection, dispatch, userManager);
		}
	}, [dispatch]);

	const handleDeepLinkingURL = (jobId: string): void => {
		const guidJobId = Guid.parse(jobId);
		const currJob = jobList.find((j) => j.id.toString() === jobId);
		const currProject = projectList.find((p) => p.id === currJob?.projectId);
		const currClient = clientList.find((c) => c.mdmClientId === currProject?.mdmClientId);

		if (!(currJob && currProject && currClient)) {
			history.replace(pageNotFoundPath);
		} else {
			batch(() => {
				dispatch(jobSelected(currJob));
				dispatch(projectSelected(currProject));
				dispatch(clientSelected(currClient));
				dispatch(fetchUserListData(currProject!.engagementId));
				dispatch(joinGroup({ hubConnection: hubConnection, groupId: jobId }));
				dispatch(joinGroup({ hubConnection: hubConnection, groupId: currClient.mdmClientId.toString() }));
				dispatch(fetchDatasetDetails(guidJobId));
				dispatch(fetchSettingsData(guidJobId));
			});
		}
	};

	return (
		<DeepLinkingContext.Provider value={{ handleDeepLinkingOrRedirectHome }}>
			{children}
		</DeepLinkingContext.Provider>
	);
};

export default DeepLinkingContextProvider;
