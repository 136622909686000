import React, { ReactElement } from 'react';
import {
    Snackbar as MaterialUISnackBar,
    SnackbarContent
} from '@material-ui/core';
import {
    snackbarWarningIcon,
    snackbarInfoIcon,
    snackbarSuccessIcon,
    snackbarErrorIcon
} from '../../assets/images/icons';

import {
    closeSnackbar,
    exitSnackbar
} from './Snackbar.slice';
import SvgIcon from '../Utility/SvgIcon';
import { RootState } from '../../app/store';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import './Snackbar.scss'
import { SnackbarType } from './Snackbar.types';

export const Snackbar = (): React.ReactElement => {
    const dispatch = useAppDispatch();

    const isSnackbarOpen = useAppSelector(
        (state: RootState) => state.snackbar.isSnackbarOpen
    );

    const snackbarMessage = useAppSelector(
        (state: RootState) => state.snackbar.snackbarMessage
    );
   
    const snackbarIcons = new Map([
        [SnackbarType.Info, snackbarInfoIcon],
        [SnackbarType.Error, snackbarErrorIcon],
        [SnackbarType.Warning, snackbarWarningIcon],
        [SnackbarType.Success, snackbarSuccessIcon]
    ]);

    const getSnackbarIcons = (type: SnackbarType) : ReactElement => {
        const localSnackbarIcons = snackbarIcons.get(type) as string; 
        return <SvgIcon className={'snackbarIcon'} svg={localSnackbarIcons} />
    };
  
    const handleExit = (): void => {
        dispatch(exitSnackbar());
    }

    const handleClose = (event: any, reason: string): void => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(closeSnackbar());
    }

    return (
        <MaterialUISnackBar
            key={snackbarMessage.key}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={isSnackbarOpen}
            onClose={handleClose}
            TransitionProps={{onExited: handleExit}}
            autoHideDuration={
                snackbarMessage.duration > 0
                    ? snackbarMessage.duration
                    : undefined
            }>
            <SnackbarContent
                className={snackbarMessage.type}
                message={
                    <span className='snackbarMessage'>
                        {getSnackbarIcons(snackbarMessage.type) }
                        {snackbarMessage.message}
                    </span>
                }
            />
        </MaterialUISnackBar>
    );
};
