import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Guid } from 'guid-typescript';
import { UserList } from '../../component/status-widget/types/UserList.types';
import { Client } from '../Types/Client.types';
import { AppDispatch, RootState } from '../../app/store';
import {
	fetchClients,
	fetchJobs,
	fetchProjects,
	fetchUserList,
} from '../../api/CEM/cem.v2.api';
import { Project } from '../Types/Project.types';
import { Job } from '../Types/Job.types';
import { showErrorSnackbar } from '../../component/snackbar/Snackbar.slice';
import { Messages } from '../../Constants/Messages';
import { CemResponse } from '../../api/CEM/CemClientResponse.types';
import { Entity } from '../../pages/home/entity/Entity';
import { CemData } from '../Types/Cem.types';

interface State {
	clients: Client[];
	selectedClient: Client;
	projects: Project[];
	selectedProject: Project;
	jobs: Job[];
	selectedJob: Job;
	userList: UserList[];
	entityListForClients: Entity[]
	dataLoaded: boolean;
	fetchedClientIds: number[]
}

const initialState: State = {
	clients: [],
	selectedClient: {} as Client,
	projects: [],
	selectedProject: {} as Project,
	jobs: [],
	selectedJob: {} as Job,
	userList: [],
	entityListForClients: [],
	dataLoaded: false,
	fetchedClientIds: []
};

export interface EngagementAttribute {
	mdmClientId: number;
	id: Guid;
}

export const fetchUserListData = createAsyncThunk<
	void,
	Guid,
	{
		dispatch: AppDispatch;
		state: RootState;
	}
>('engagement/fetchUserList', async (engagementId, thunkAPI) => {
	await fetchUserList(engagementId).then(function (result) {
		if (result.Success) {
			const data = JSON.parse(result.Data.toString()) as CemResponse;
			data.results = data.results as UserList[];
			thunkAPI.dispatch(setUserList(data.results as UserList[]));
			return;
		}
		showErrorSnackbar(thunkAPI.dispatch, Messages.UNABLE_TO_GET_STATUS_WIDGET_USER_LIST);
	});
});

export const fetchCemData = createAsyncThunk<
	void,
	string[],
	{
		dispatch: AppDispatch;
		state: RootState;
	}
>('job/fetchCemData', async (filteredClientIds, thunkAPI) => {
	fetchClients()
		.then(function (values) {
			if (values.Success) {
				const clientData = JSON.parse(values.Data.toString()) as CemResponse;
				let clients: Client[];
				if (filteredClientIds.length > 0 && clientData) {
					clients = (clientData.results as Client[])
						.filter(x => filteredClientIds.includes(x.mdmClientId.toString()))
				} else {
					clients = clientData.results as Client[];
				}
				(async () => {
					const clientJobs = fetchJobs(clients[0].mdmClientId).then((result) => {
						return JSON.parse(result.Data.toString()).results as Job[];

					});
					const clientProjects = fetchProjects(clients[0].mdmClientId).then((result) => {
						return JSON.parse(result.Data.toString()).results as Project[];
					});
					thunkAPI.dispatch(setCemData({ jobs: await clientJobs, clients: clients, projects: await clientProjects }));
					thunkAPI.dispatch(setCemDataLoadedFlag(true));
					thunkAPI.dispatch(setFetchedClientIds([clients[0].mdmClientId]));
				})();
			} else {
				showErrorSnackbar(thunkAPI.dispatch, Messages.EXCEPTION_FOR_FETCH)
			}
		})
});

const cemSlice = createSlice({
	name: 'cem',
	initialState,
	reducers: {
		setClients: (state, action: PayloadAction<Client[]>) => {
			state.clients = action.payload;
		},
		clientSelected: (state, action: PayloadAction<Client | undefined>) => {
			if (action.payload) {
				state.selectedClient = action.payload;
			}
		},
		setProjects: (state, action: PayloadAction<Project[]>) => {
			state.projects = action.payload;
		},
		projectSelected: (state, action: PayloadAction<Project | undefined>) => {
			if (action.payload) {
				state.selectedProject = action.payload;
			}
		},
		setJobs: (state, action: PayloadAction<Job[]>) => {
			state.jobs = action.payload;
		},
		jobSelected: (state, action: PayloadAction<Job | undefined>) => {
			if (action.payload) {
				state.selectedJob = action.payload;
			}
		},
		setCemData: (state, action: PayloadAction<CemData>) => {
			return { ...state, jobs: action.payload.jobs, projects: action.payload.projects, clients: action.payload.clients }
		},
		setUserList: (state, action: PayloadAction<UserList[]>) => {
			state.userList = action.payload;
		},
		setEntityListForClients: (state, action: PayloadAction<Entity[]>) => {
			return { ...state, entityListForClients: action.payload };
		},
		setCemDataLoadedFlag: (state, action: PayloadAction<boolean>) => {
			state.dataLoaded = action.payload;
		},
		setFetchedClientIds: (state, action: PayloadAction<number[]>) => {
			return {...state, fetchedClientIds: action.payload};
		},
		clearSelections: (state) => {
			state.selectedProject = initialState.selectedProject;
			state.selectedJob = initialState.selectedJob;
		},
		clearAllCemData: () => {
			return { ...initialState };
		},
	},
});

export const {
	clientSelected,
	jobSelected,
	setUserList,
	projectSelected,
	clearSelections,
	setEntityListForClients,
	setCemData,
	setCemDataLoadedFlag,
	clearAllCemData,
	setJobs,
	setProjects,
	setFetchedClientIds
} = cemSlice.actions;

export default cemSlice.reducer;
