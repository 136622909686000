import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { downloadActiveDataset, downloadEmptyTemplate, downloadReports } from '../../../api/GlobalActions/GlobalActions.api';
import { currDateAndTime, getExtension, saveStream, saveStreamCSV } from '../../../core/Utils/utils';
import { showErrorSnackbar, showSuccessSnackbar } from '../../snackbar/Snackbar.slice';
import { ProcessId } from '../../../core/Types/ProcessId.types';
import { Messages } from '../../../Constants/Messages';

export interface GlobalActionsState {}

const initialState: GlobalActionsState = {};

export const downloadTemplate = createAsyncThunk('globalActions/downloadEmptyTemplate', async (arg, thunkAPI) => {
	const state: any = thunkAPI.getState();
	const response = await downloadEmptyTemplate(state.cem.selectedJob.id);
	const fileName = 'TRaDE Import Template' + getExtension(response.ContentType ?? 'application/octet-stream');
	if (response.Success) {
		saveStreamCSV(fileName, response.Data);
		showSuccessSnackbar(thunkAPI.dispatch, Messages.DATA_TEMPLATE_DOWNLOAD_SUCCESS);
	} else {
		showErrorSnackbar(thunkAPI.dispatch, Messages.DATA_TEMPLATE_DOWNLOAD_FAILED);
	}
});

export const downloadPopulatedTemplate = createAsyncThunk<void, ProcessId>(
	'globalActions/downloadActiveDataset',
	async (processId, thunkAPI) => {
		const response = await downloadActiveDataset(processId.jobId, processId.datasetId);
		const fileName = currDateAndTime() + '_populated_dataset';
		if (response.Success) { 
			saveStream( response.ContentType as string, fileName, response.Data);
			showSuccessSnackbar(thunkAPI.dispatch, Messages.DATA_TEMPLATE_DOWNLOAD_SUCCESS);
		} else {
			showErrorSnackbar(thunkAPI.dispatch, Messages.DATA_TEMPLATE_DOWNLOAD_FAILED);
		}
	},
);

export const downloadOutputReports = createAsyncThunk<void, ProcessId>(
	'globalActions/downloadOutputReports',
	async (processId, thunkAPI) => {
		let response = await downloadReports(processId);
		const fileName = `${currDateAndTime()}_${processId.jobId}_Calculation_Reports`;
		if (response.Success) {
			saveStream(response.ContentType ?? null, fileName, response.Data);
			showSuccessSnackbar(thunkAPI.dispatch, Messages.DOWNLOAD_OUTPUT_REPORTS_SUCCESS);
		} else {
			showErrorSnackbar(thunkAPI.dispatch, Messages.DOWNLOAD_OUTPUT_REPORTS_FAILED);
		}
	},
);

const globalActionsSlice = createSlice({
	name: 'globalActions',
	initialState,
	reducers: {},
});

export default globalActionsSlice.reducer;
