import { UploadImportFormData } from './UploadImportFormData.types';
import { FetchApi } from '../../api/ApiFetch/ApiFetch';
import { HTTPMethod } from '../../api/ApiFetch/HTTPMethod.types';
import { ServiceType } from '../../api/ApiFetch/ServiceType.types';

export async function uploadFormData(params: UploadImportFormData): Promise<Response> {
    const formData = new FormData();
    formData.append('DatasetName', params.datasetName);
    formData.append('ClientId', params.clientId);
    formData.append('File', params.file);
    formData.append('IsDeleteData', `${params.deletePreexistingData}`);
    formData.append('IsBypassStaging', `${params.bypassStaging}`);

    const response = await FetchApi({
        endpoint: 'job/' + params.jobId + '/upload-data',
        method: HTTPMethod.Post,
        apiType: ServiceType.Import,
        body: formData,
    });
    return response;
}
