import { ApiUrls } from '../ApiConstants/ApiUrls';
import { FetchApi } from '../ApiFetch/ApiFetch';
import { CreateTextResponse, ResponseData } from '../ApiFetch/ApiResponse';
import { HTTPMethod } from '../ApiFetch/HTTPMethod.types';
import { ServiceType } from '../ApiFetch/ServiceType.types';
import { Guid } from 'guid-typescript';

export const runCalculationCall = async (data: any): Promise<ResponseData> => {
	const formattedUrl = ApiUrls.RUN_CALCULATION_PREDEFINED.replace('{jobId}', data.JobId);

	const response = await FetchApi({
		endpoint: formattedUrl,
		method: HTTPMethod.Post,
		headers: {
			'Content-Type': 'application/json',
		},
		apiType: ServiceType.Calculation,
		body: JSON.stringify(data.Settings),
		apiVersion: 2,
	}).catch((error) => {
		return error;
	});
	return CreateTextResponse(response as Response);
};
export const runCalculationWithSetting = async (data: any): Promise<ResponseData> => {
	const formattedUrl = ApiUrls.RUN_CALCULATION_WITH_SETTINGS.replace('{jobId}', data.JobId);

	const response = await FetchApi({
		endpoint: formattedUrl,
		method: HTTPMethod.Post,
		headers: {
			'Content-Type': 'application/json',
		},
		apiType: ServiceType.Calculation,
		body: JSON.stringify(data.Settings),
		apiVersion: 2,
	}).catch((error) => {
		return error;
	});
	return CreateTextResponse(response as Response);
};
export const lastCalculationRun = async (jobId: Guid, apiVersion: number = 1): Promise<ResponseData> => {
	const formattedUrl = ApiUrls.RUN_CALCULATION_MOST_RECENT.replace('{jobId}', jobId.toString());
	const response = await FetchApi({
		endpoint: formattedUrl,
		method: HTTPMethod.Get,
		headers: {
			'Content-Type': 'application/json',
		},
		apiType: ServiceType.Calculation,
		apiVersion: apiVersion,
	}).catch((error) => {
		return error;
	});
	return CreateTextResponse(response as Response);
};

export const fetchMostRecentCalculationRuns = async (jobId: Guid): Promise<ResponseData> => {
	const formattedUrl = ApiUrls.RUN_CALCULATION_MOST_RECENT.replace('{jobId}', jobId.toString());
	const response = await FetchApi({
		endpoint: formattedUrl,
		method: HTTPMethod.Get,
		headers: {
			'Content-Type': 'application/json',
		},
		apiType: ServiceType.Calculation,
		apiVersion: 2,
	}).catch((error) => {
		return error;
	});
	return CreateTextResponse(response as Response);
};

export const getBookToTaxReportContent = async (jobId: Guid): Promise<ResponseData> => {
	const formattedUrl = ApiUrls.GET_BOOK_TO_TAX_REPORT_CONTENT.replace('{jobId}', jobId.toString());
	const response = await FetchApi({
		endpoint: formattedUrl,
		method: HTTPMethod.Get,
		headers: {
			'Content-Type': 'application/json',
		},
		apiType: ServiceType.Calculation,
	}).catch((error) => {
		return error;
	});
	return CreateTextResponse(response as Response);
};

export const getDividendReportContent = async (jobId: Guid): Promise<ResponseData> => {
	const formattedUrl = ApiUrls.GET_DIVIDEND_REPORT_CONTENT.replace('{jobId}', jobId.toString());
	const response = await FetchApi({
		endpoint: formattedUrl,
		method: HTTPMethod.Get,
		headers: {
			'Content-Type': 'application/json',
		},
		apiType: ServiceType.Calculation,
	}).catch((error) => {
		return error;
	});
	return CreateTextResponse(response as Response);
};

export const getStraddlesReportContent = async (jobId: Guid): Promise<ResponseData> => {
	const formattedUrl = ApiUrls.GET_STRADDLE_REPORT_CONTENT.replace('{jobId}', jobId.toString());
	const response = await FetchApi({
		endpoint: formattedUrl,
		method: HTTPMethod.Get,
		headers: {
			'Content-Type': 'application/json',
		},
		apiType: ServiceType.Calculation,
	}).catch((error) => {
		return error;
	});
	return CreateTextResponse(response as Response);
};

export const getCombinedReportContent = async (jobId: Guid): Promise<ResponseData> => {
	const formattedUrl = ApiUrls.GET_COMBINED_REPORT_CONTENT.replace('{jobId}', jobId.toString());
	const response = await FetchApi({
		endpoint: formattedUrl,
		method: HTTPMethod.Get,
		headers: {
			'Content-Type': 'application/json',
		},
		apiType: ServiceType.Calculation,
	}).catch((error) => {
		return error;
	});
	return CreateTextResponse(response as Response);
};

export const getOneToOneReportContent = async (jobId: Guid): Promise<ResponseData> => {
	const formattedUrl = ApiUrls.GET_ONETOONE_REPORT_CONTENT.replace('{jobId}', jobId.toString());
	const response = await FetchApi({
		endpoint: formattedUrl,
		method: HTTPMethod.Get,
		headers: {
			'Content-Type': 'application/json',
		},
		apiType: ServiceType.Calculation,
	}).catch((error) => {
		return error;
	});
	return CreateTextResponse(response as Response);
};

export const latestCalculationRun = async (jobId: Guid, take?: number): Promise<ResponseData> => {
	const formattedUrl = ApiUrls.GET_RECENT_CALCULATIONS.replace('{jobId}', jobId.toString()).replace(
		'{take}',
		take !== null ? '' : String(take),
	);
	const response = await FetchApi({
		endpoint: formattedUrl,
		method: HTTPMethod.Get,
		headers: {
			'Content-Type': 'application/json',
		},
		apiType: ServiceType.Calculation,
	}).catch((error) => {
		return error;
	});
	return CreateTextResponse(response as Response);
};

export const getDashboardSummary = async (jobId: Guid, calculationProcessId: Guid): Promise<ResponseData> => {
	const url = ApiUrls.GET_DASHBOARD_SUMMARY.replace('{jobId}', jobId.toString()).replace(
		'{calculationProcessId}',
		calculationProcessId.toString(),
	);
	const response = await FetchApi({
		endpoint: url,
		method: HTTPMethod.Get,
		headers: {
			'Content-Type': 'application/json',
		},
		apiType: ServiceType.Calculation,
		apiVersion: 2,
	}).catch((error) => {
		return error;
	});
	return CreateTextResponse(response as Response);
};
