import {
	CalculationFailureMessage,
	CalculationInitiationMessage,
	CalculationSuccessMessage,
	IconGroup,
} from '../Types/Notifications.types';
import { addNotification, addUserNotification, setIsStandardReportEnabledFlag } from '../Slices/Notifications.slice';
import {
	handleGetStatusDetail,
	setTemporaryFailedCalculationNotificationStatusWidgetState,
	setTemporarySuccessfulCalculationNotificationStatusWidgetState,
} from '../../component/status-widget/statusWidget.slice';
import { AppDispatch } from '../../app/store';
import { Messages } from '../../Constants/Messages';
import {
	handleSetRecentCompletedCalcRunState,
	setCalculationInProgress,
} from '../../component/TopMenu/CalculationRun.slice';
import { Guid } from 'guid-typescript';
import { showErrorSnackbar, showSuccessSnackbar } from '../../component/snackbar/Snackbar.slice';

export function addCalculationSuccessNotification(
	calculationSuccessMessage: CalculationSuccessMessage,
	dispatch: AppDispatch,
) {
	dispatch(
		addNotification({
			type: IconGroup.Calculation,
			user: calculationSuccessMessage.startedBy,
			dateTime: calculationSuccessMessage.completedOn,
			message: Messages.CALCULATIONS_SUCCEEDED,
			groupId: calculationSuccessMessage.groupId,
			id: calculationSuccessMessage.calculationId,
		}),
	);
	dispatch(handleSetRecentCompletedCalcRunState(Guid.parse(calculationSuccessMessage.groupId)));
	dispatch(
		setTemporarySuccessfulCalculationNotificationStatusWidgetState({
			hierarchyLevelId: Guid.parse(calculationSuccessMessage.groupId),
			currentlyWith: calculationSuccessMessage.startedBy,
			lastCalculationDate: new Date(calculationSuccessMessage.completedOn),
		}),
	);
}

export function addCalculationFailureNotification(
	calculationFailureMessage: CalculationFailureMessage,
	dispatch: AppDispatch,
) {
	dispatch(
		addNotification({
			type: IconGroup.Calculation,
			user: calculationFailureMessage.startedBy,
			dateTime: calculationFailureMessage.completedOn,
			message: Messages.CALCULATIONS_FAILED,
			groupId: calculationFailureMessage.groupId,
		}),
	);
	dispatch(handleSetRecentCompletedCalcRunState(Guid.parse(calculationFailureMessage.groupId)));
	dispatch(
		setTemporaryFailedCalculationNotificationStatusWidgetState({
			hierarchyLevelId: Guid.parse(calculationFailureMessage.groupId),
			currentlyWith: calculationFailureMessage.startedBy,
		}),
	);
}

export function addUserCalculationSuccessNotification(
	calculationSuccessMessage: CalculationSuccessMessage,
	dispatch: AppDispatch,
) {
	dispatch(
		addUserNotification({
			type: IconGroup.Calculation,
			user: calculationSuccessMessage.startedBy,
			dateTime: calculationSuccessMessage.completedOn,
			message: Messages.CALCULATIONS_SUCCEEDED,
			groupId: calculationSuccessMessage.groupId,
			id: calculationSuccessMessage.calculationId,
		}),
	);
	showSuccessSnackbar(dispatch, Messages.CALCULATIONS_SUCCEEDED);
	dispatch(handleSetRecentCompletedCalcRunState(Guid.parse(calculationSuccessMessage.groupId)));
	dispatch(
		setIsStandardReportEnabledFlag({
			hierarchyLevelId: calculationSuccessMessage.groupId,
			isStandardReportEnabled: true,
		}),
	);
	dispatch(
		setTemporarySuccessfulCalculationNotificationStatusWidgetState({
			hierarchyLevelId: Guid.parse(calculationSuccessMessage.groupId),
			currentlyWith: calculationSuccessMessage.startedBy,
			lastCalculationDate: new Date(calculationSuccessMessage.completedOn),
		}),
	);
}

export function addUserCalculationFailureNotification(
	calculationFailureMessage: CalculationFailureMessage,
	dispatch: AppDispatch,
) {
	dispatch(
		addUserNotification({
			type: IconGroup.Calculation,
			user: calculationFailureMessage.startedBy,
			dateTime: calculationFailureMessage.completedOn,
			message: Messages.CALCULATIONS_FAILED,
			groupId: calculationFailureMessage.groupId,
		}),
	);
	showErrorSnackbar(dispatch, Messages.CALCULATIONS_FAILED);
	dispatch(handleSetRecentCompletedCalcRunState(Guid.parse(calculationFailureMessage.groupId)));

	dispatch(
		setTemporaryFailedCalculationNotificationStatusWidgetState({
			hierarchyLevelId: Guid.parse(calculationFailureMessage.groupId),
			currentlyWith: calculationFailureMessage.startedBy,
		}),
	);
}

export function addCalculationInitiationNotification(
	calculationInitiationMessage: CalculationInitiationMessage,
	dispatch: AppDispatch,
) {
	dispatch(
		addNotification({
			type: IconGroup.Calculation,
			user: calculationInitiationMessage.startedBy,
			dateTime: calculationInitiationMessage.startedOn,
			message: Messages.CALCULATIONS_INITIATION,
			groupId: calculationInitiationMessage.groupId,
		}),
	);
	dispatch(setCalculationInProgress(true));
	dispatch(handleGetStatusDetail(Guid.parse(calculationInitiationMessage.groupId)));
}

export function addUserCalculationInitiationNotification(
	calculationInitiationMessage: CalculationInitiationMessage,
	dispatch: AppDispatch,
) {
	dispatch(
		addUserNotification({
			type: IconGroup.Calculation,
			user: calculationInitiationMessage.startedBy,
			dateTime: calculationInitiationMessage.startedOn,
			message: Messages.CALCULATIONS_INITIATION,
			groupId: calculationInitiationMessage.groupId,
		}),
	);
	dispatch(setCalculationInProgress(true));
	dispatch(handleGetStatusDetail(Guid.parse(calculationInitiationMessage.groupId)));
}
