export enum SignalrHubMethods {
	ImportFailureNotification = 'ReceiveImportFailureNotification',
	ImportSuccessNotification = 'ReceiveImportSuccessNotification',
	CalculationSuccessNotification = 'ReceiveCalculationSuccessMessage',
	CalculationInitiationNotification = 'ReceiveCalculationInitiationMessage',
	CalculationFailureNotification = 'ReceiveCalculationFailureMessage',
	ClientReferenceSuccessNotification = 'ReceiveClientReferenceUploadSuccessNotification',
	ClientReferenceFailureNotification = 'ReceiveClientReferenceUploadFailureNotification',
	UserImportFailureNotification = 'ReceiveUserImportFailureNotification',
	UserImportSuccessNotification = 'ReceiveUserImportSuccessNotification',
	UserClientReferenceFailureNotification = 'ReceiveRsmStandardReferenceUploadFailureNotification',
	UserClientReferenceSuccessNotification = 'ReceiveRsmStandardReferenceUploadSuccessNotification',
	UserCalculationSuccessNotification = 'ReceiveUserCalculationSuccessMessage',
	UserCalculationInitiationNotification = 'ReceiveUserCalculationInitiationMessage',
	SettingsSuccessMessage = 'ReceiveSettingsSuccessMessage',
	UserSettingsSuccessMessage = 'ReceiveUserSettingsSuccessMessage',
	UserCalculationFailureNotification = 'ReceiveUserCalculationFailureMessage',
	StatusWidgetNotification = 'ReceiveStatusWidgetNotification',
	StatusWidgetUserNotification = 'ReceiveUserStatusWidgetNotification',
	ClientRulesetSuccessNotification = 'ReceiveClientRulesetUploadSuccessNotification',
	ClientRulesetFailureNotification = 'ReceiveClientRulesetUploadFailureNotification',
	UserClientRulesetFailureNotification = 'ReceiveRsmStandardRulesetUploadFailureNotification',
	UserClientRulesetSuccessNotification = 'ReceiveRsmStandardRulesetUploadSuccessNotification',
}
