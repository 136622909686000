import _ from 'lodash';
import moment from 'moment';
import { TextStrings } from '../../Constants/TextStrings';
import { CalculationStepStatus } from '../Types/Calculation.types';
import { excelSheetContentType } from './constants';

export const saveStreamCSV = ( filename: string, blobPart: BlobPart): void => {
	saveStreamAsFile(excelSheetContentType, filename, blobPart);
};

export const saveStream = (blobType: string | null, filename: string, blobPart: BlobPart): void => {
	if (!!blobType) {
		const ext = getExtension(blobType);
		saveStreamAsFile(blobType, filename + ext, blobPart);
	}
};

const saveStreamAsFile = (blobType: string, filename: string, blobPart: BlobPart): void => {
	const anchor = document.createElement('a');
	anchor.download = filename;
	if (window.URL.createObjectURL) {
		// Everything else new.
		const blobObject = new Blob([blobPart], {
			type: blobType,
		});
		anchor.href = window.URL.createObjectURL(blobObject);
	}

	if (document.createEvent) {
		const event = document.createEvent('MouseEvents');
		event.initEvent('click', true, true);
		anchor.dispatchEvent(event);
	} else {
		anchor.click();
	}
};

export const getDisplayStatusName = (status: number) => {
	switch (status) {
		case 0:
			return TextStrings.PROCESSING;
		case 1:
		case 2:
		case 4:
		case 5:
		case 10:
			return TextStrings.FAILED;
		case 3:
			return TextStrings.SUCCESS;
		default:
			return '';
	}
};

export const getDisplayMessageForCalculationStatus = (status: CalculationStepStatus) => {
	switch (status) {
		case CalculationStepStatus.Calculating:
		case CalculationStepStatus.NotStarted:
		case CalculationStepStatus.Enriching:
		case CalculationStepStatus.ReadyForPackaging:
			return TextStrings.PROCESSING;
		case CalculationStepStatus.Failed:
		case CalculationStepStatus.PackagingFailed:
		case CalculationStepStatus.TimedOut:
			return TextStrings.FAILED;
		case CalculationStepStatus.Downloaded:
		case CalculationStepStatus.Complete:
			return TextStrings.SUCCESS;
		default:
			return '';
	}
};

export const checkCalculationInProgress = (status: CalculationStepStatus) => {
	switch (status) {
		case CalculationStepStatus.Calculating:
		case CalculationStepStatus.NotStarted:
		case CalculationStepStatus.Enriching:
		case CalculationStepStatus.ReadyForPackaging:
			return true;
		default:
			return false;
	}
};

export const currDateAndTime = () => {
	function ConvertToDoubleDigits(num: string) {
		return num.padStart(2, '0');
	}

	//converting the Default mm//dd//yy format to yyyymmdd_hhmmss format
	const currTimeAndDate =
		new Date().getFullYear().toString() +
		ConvertToDoubleDigits((new Date().getMonth() + 1).toString()) +
		ConvertToDoubleDigits(new Date().getDate().toString()) +
		'_' +
		ConvertToDoubleDigits(new Date().getHours().toString()) +
		ConvertToDoubleDigits(new Date().getMinutes().toString()) +
		ConvertToDoubleDigits(new Date().getSeconds().toString());
	return currTimeAndDate;
};

export const getExtension = (blobType: string): string => {
	let extension = '';
	switch (blobType) {
		case 'application/zip':
			extension = '.zip';
			break;
		case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
			extension = '.xlsx';
			break;
		case 'text/csv':
			extension = '.csv';
			break;
		case 'application/json':
			extension = '.json';
			break;
		case 'application/vnd.ms-excel.sheet.macroEnabled.12':
			extension = '.xlsm';
			break;
		default:
			console.error(blobType + ' is invalid or not accepted.');
			break;
	}
	return extension;
};

export const sortByDescending = (array: any): any => {
	return _.orderBy(
		array,
		(o: any) => {
			return moment(o.ModifiedDate ?? o.ModifiedOn);
		},
		['desc'],
	);
};

export const sanitizeUserInput = (input: string): string => {
	return input.replace(new RegExp(/[^a-zA-Z0-9 ,-._()@]/g), '');
};
