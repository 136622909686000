import { Section } from './Section';

export enum InputType {
	Asset = 1,
	Payment = 7,
	Rollover = 8,
	TaxLots = 9,
	RealizedGainLoss = 10,
	RolloverOriginal = 11,
	Diagnostics = 13,
}

export enum LibraryType {
	ClientAssetWarehouse = 1,
	ClientDividendWarehouse = 2,
	ClientRuleset = 3,
	ReferenceAssetType = 19,
	ReferenceExchange = 20,
	ReferenceCountry = 21,
	ReferenceTransaction = 22,
	ReferenceCurrency = 23,
}

export enum GroupType {
	Dataset = 101,
	Library,
}

export enum AdminType {
	Default = 201,
	Client,
}

export interface InputDetailsProps {
	InputName: string;
	InputType?: InputType | LibraryType;
	Section: Section;
	SubSectionName: string;
	Group: GroupType;
}
