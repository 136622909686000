import { ApiUrls } from '../ApiConstants/ApiUrls';
import { FetchApi } from '../ApiFetch/ApiFetch';
import { CreateTextResponse, ResponseData } from '../ApiFetch/ApiResponse';
import { HTTPMethod } from '../ApiFetch/HTTPMethod.types';
import { ServiceType } from '../ApiFetch/ServiceType.types';
import { Guid } from 'guid-typescript';

export const setJobAsFavoriteApi = async (jobId: Guid): Promise<ResponseData> => {
    const formattedUrl = ApiUrls.CREATE_FAVORITE_JOBS.replace('{jobId}', jobId.toString());
    const response = await FetchApi({
        endpoint: formattedUrl,
        method: HTTPMethod.Post,
        headers: {
            'Content-Type': 'application/json'
        },
        apiType: ServiceType.Common,
    }).catch((error) => {
        return error;
    });
    return CreateTextResponse(response);
};

export const getFavoriteJobsApi = async (): Promise<ResponseData> => {
    const formattedUrl = ApiUrls.GET_FAVORITE_JOBS;
    const response = await FetchApi({
        endpoint: formattedUrl,
        method: HTTPMethod.Get,
        apiType: ServiceType.Common,
    }).catch((error) => {
        return error;
    });
    return CreateTextResponse(response);
};

export const removeJobAsFavoriteApi = async (jobId: any): Promise<ResponseData> => {
    const formattedUrl = ApiUrls.REMOVE_FAVORITE_JOBS.replace('{jobId}', jobId.toString());
    const response = await FetchApi({
        endpoint: formattedUrl,
        method: HTTPMethod.Delete,
        apiType: ServiceType.Common,
    }).catch((error) => {
        return error;
    });
    return CreateTextResponse(response);
};