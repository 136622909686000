import { DatasetDetailsResponse, Warning, WarningLevel } from '../Types/DatasetDetailsResponse.types';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../../app/store';
import { fetchActiveDatasetDetails, fetchInputByType } from '../../api/Input/InputDetails.api';
import { Guid } from 'guid-typescript';
import { InputFileRequest } from '../Types/InputFileRequest.types';
import { GroupType, InputType } from '../Types/Input.types';
import { Messages } from '../../Constants/Messages';
import { IconGroup, NotificationType, SignalRImportMessage } from '../Types/Notifications.types';
import { showErrorSnackbar } from '../../component/snackbar/Snackbar.slice';
import { ERROR_MESSAGE } from '../Utils/constants';
import StatusCode from 'status-code-enum';
import { conditionalImportStatus } from '../Utils/CalculationStatus';

interface State {
	datasetDetails: DatasetDetailsResponse;
}

const initialState: State = {
	datasetDetails: {} as DatasetDetailsResponse,
};

export const fetchDatasetDetails = createAsyncThunk<
	void,
	Guid,
	{
		dispatch: AppDispatch;
		state: RootState;
	}
>('dataset/fetchActiveDatasetDetails', async (jobId: Guid, thunkAPI) => {
	thunkAPI.dispatch(setDatasetToInitial());
	fetchActiveDatasetDetails(jobId).then(async function (result) {
		if (result.Success) {
			const datasetDetails = JSON.parse(result.Data.toString()) as DatasetDetailsResponse;
			if (conditionalImportStatus(datasetDetails)) {
				const inputFileRequest: InputFileRequest = {
					jobId: jobId,
					datasetId: datasetDetails.datasetId,
					type: InputType.Diagnostics,
					group: GroupType.Dataset,
					settingsId: Guid.EMPTY,
				};
				const response = await fetchInputByType(inputFileRequest);
				if (response.Success) {
					const data = JSON.parse(response.Data.toString());
					const warning = JSON.parse(data) as Warning[];
					const errors = warning.find(z => z.WarningLevel == WarningLevel.Error);
					if (errors) {
						datasetDetails.hasError = true;
					} else {
						datasetDetails.hasWarning = true;
					}
				} else if (response.StatusCode === StatusCode.ClientErrorNotFound) {
					datasetDetails.hasWarning = false;
				} else {
					showErrorSnackbar(thunkAPI.dispatch, ERROR_MESSAGE);
				}
			}
			thunkAPI.dispatch(setDatasetDetails(datasetDetails));
		}
	});
});

export const handleInputNotification = createAsyncThunk<
	void,
	SignalRImportMessage,
	{
		dispatch: AppDispatch;
		state: RootState;
	}
>('dataset/addUserNotificationByInput', async (signalRImportMessage: SignalRImportMessage, thunkAPI) => {
	const state = thunkAPI.getState();
	const selectedJob = state.cem.selectedJob;
	const importMessage = signalRImportMessage.importMessage;
	const currentGroup = state.notifications.currentGroup;
	const jobId = Guid.parse(importMessage.groupId.toLowerCase());
	if (
		signalRImportMessage.notificationType === NotificationType.User &&
		currentGroup.includes(importMessage.groupId?.toLowerCase())
	) {
		return;
	}
	thunkAPI.dispatch(setDatasetToInitial());
	fetchActiveDatasetDetails(jobId).then(async function (result) {
		if (result.Success) {
			const datasetDetails = JSON.parse(result.Data.toString()) as DatasetDetailsResponse;
			const importSuccess = conditionalImportStatus(datasetDetails);
			let type = IconGroup.Import;
			let message = importSuccess ? Messages.IMPORT_SUCCESSFUL : Messages.IMPORT_FAILED;
			if (importSuccess) {
				const inputFileRequest: InputFileRequest = {
					jobId: jobId,
					datasetId: datasetDetails.datasetId,
					type: InputType.Diagnostics,
					group: GroupType.Dataset,
					settingsId: Guid.EMPTY,
				};
				const response = await fetchInputByType(inputFileRequest);
				if (response.Success) {
					const data = JSON.parse(response.Data.toString());
					const warning = JSON.parse(data) as Warning[];
					const errors = warning.find(z => z.WarningLevel == WarningLevel.Error);
					if (errors) {
						message = Messages.IMPORT_DIAGNOSTICS_ERROR;
						datasetDetails.hasError = true;
					} else {
						datasetDetails.hasWarning = true;
						message = Messages.IMPORT_SUCCESS_WITH_WARNING;
					}
					type = IconGroup.ImportSuccessWithWarning;
				} else if (response.StatusCode === StatusCode.ClientErrorNotFound) {
					datasetDetails.hasWarning = false;
				} else {
					showErrorSnackbar(thunkAPI.dispatch, ERROR_MESSAGE);
				}
			}
			if (selectedJob.id && selectedJob.id.toString() === importMessage.groupId.toLowerCase()) {
				thunkAPI.dispatch(setDatasetDetails(datasetDetails));
			}
			thunkAPI.dispatch(
				signalRImportMessage.signalrHubMethods({
					type: type,
					user: importMessage.importedBy,
					dateTime: importMessage.importedOn,
					message: message,
					groupId: importMessage.groupId,
				}),
			);
		}
	});
});

const datasetSlice = createSlice({
	name: 'dataset',
	initialState,
	reducers: {
		setDatasetDetails(state, action: PayloadAction<DatasetDetailsResponse>): any {
			return { ...state, datasetDetails: action.payload };
		},
		setDatasetToInitial(): State {
			return { ...initialState };
		},
	},
});

export const { setDatasetDetails, setDatasetToInitial } = datasetSlice.actions;

export default datasetSlice.reducer;
