import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchClientAssetOfInterestInput, fetchRSMAssetOfInterestInput } from '../../../api/AssetsOfInterest/AssetOfInterestImport.api';
import { AppDispatch, RootState } from '../../../app/store';
import { showErrorSnackbar } from '../../../component/snackbar/Snackbar.slice';
import { Messages } from '../../../Constants/Messages';
import { AssetOfInterestInput } from './AssetOfInterest.types';

interface State {
	clientAssetOfInterestData: AssetOfInterestInput[];
	rsmAssetOfInterestData: AssetOfInterestInput[];
}

const initialState: State = {
	clientAssetOfInterestData: [],
	rsmAssetOfInterestData: []
};

export const fetchClientAssetOfInterestData = createAsyncThunk<
	void,
	string,
	{
		dispatch: AppDispatch;
		state: RootState;
	}
>('assetOfInterest/fetchClientAssetOfInterestData', async (clientId, thunkAPI) => {
	fetchClientAssetOfInterestInput(clientId).then(function (result) {
		if (!result.Success) {
			showErrorSnackbar(thunkAPI.dispatch, Messages.EXCEPTION_FOR_FETCH);
			return;
		}
		var data = JSON.parse(result.Data.toString()) as AssetOfInterestInput[];
		data.map(function (info) {
			info.ProvidedByRSM = false;
			return info;
		});
		thunkAPI.dispatch(
			setAssetOfInterestInputData(data)
		);
	});
});
export const fetchRSMAssetOfInterestData = createAsyncThunk<
	void,
	void,
	{
		dispatch: AppDispatch;
		state: RootState;
	}
>('assetOfInterest/fetchRSMAssetOfInterestData', async (_: void, thunkAPI) => {
	fetchRSMAssetOfInterestInput().then(function (result) {
		if (result.Success) {
			var data = JSON.parse(result.Data.toString()) as AssetOfInterestInput[];
			data.map(function (info) {
				info.ProvidedByRSM = true;
				return info;
			});
			thunkAPI.dispatch(
				setRSMAssetOfInterestData(data)
			);
			return;
		}
		showErrorSnackbar(thunkAPI.dispatch, Messages.EXCEPTION_FOR_FETCH);
	});
});

const assetOfInterestSlice = createSlice({
	name: 'assetOfInterest',
	initialState,
	reducers: {
		setAssetOfInterestInputData: (
			state,
			action: PayloadAction<AssetOfInterestInput[]>
		) => {
			state.clientAssetOfInterestData = action.payload;
		},
		setRSMAssetOfInterestData: (
			state,
			action: PayloadAction<AssetOfInterestInput[]>
		) => {
			state.rsmAssetOfInterestData = action.payload;
		},
	},
});

export const { setAssetOfInterestInputData, setRSMAssetOfInterestData } = assetOfInterestSlice.actions;

export default assetOfInterestSlice.reducer;
