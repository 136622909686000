import { FetchApi } from '../ApiFetch/ApiFetch';
import { ServiceType } from '../ApiFetch/ServiceType.types';
import { HTTPMethod } from '../ApiFetch/HTTPMethod.types';
import { Guid } from 'guid-typescript';
import { Library } from '../../component/Settings/Settings.types';
import { CreateTextResponse, ResponseData } from '../ApiFetch/ApiResponse';

export const fetchSettings = async (jobId: Guid): Promise<ResponseData> => {
	const response = await FetchApi({
		endpoint: 'enrichment/job/' + jobId + '/settings',
		apiType: ServiceType.Enrichment,
		method: HTTPMethod.Get,
	}).catch((error) => {
		return error;
	});
	return CreateTextResponse(response);
};

export const saveSettings = async (clientId: number, jobId: Guid, library: Library): Promise<ResponseData> => {
	const response = await FetchApi({
		endpoint: 'enrichment/client/' + clientId + '/job/' + jobId + '/settings',
		apiType: ServiceType.Enrichment,
		method: HTTPMethod.Post,
		body: JSON.stringify(library),
		headers: { 'Content-Type': 'application/json' },
	}).catch((error) => {
		return error;
	});
	return CreateTextResponse(response);
};
