import { useState } from 'react';
import { AnalysisContext } from './AnalysisContext';
import { fetchAnalysisSettingsByJob } from '../../api/Settings/CalculationSettings.api';
import { ResponseData } from '../../api/ApiFetch/ApiResponse';
import {
	AccrualBasis,
	Analysis,
	AnalysisGroup,
	AnalysisGroupValue,
	AnalysisSettingByJob,
	AnalysisSettingsValue,
	Bifurcation,
	CalculationBaseGroup,
	Character,
	ClientType,
	ConstructiveSales,
	Dividend,
	ForcedConstructiveSales,
	Hedging,
	HedgingType,
	IRC475,
	ReliefMethodOptions,
	Reporting,
	MTMSubSetting,
	MTMSubSettingDefault
} from '../../component/Settings/Settings.types';
import { showErrorSnackbar } from '../../component/snackbar/Snackbar.slice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { Messages } from '../../Constants/Messages';
import { Guid } from 'guid-typescript';
import { DefaultCalculationSetting } from '../../component/Settings/DefaultCalculationSetting';
import { RootState } from '../../app/store';

const AnalysisContextProvider = ({ children }: any) => {
	const dispatch = useAppDispatch();
	const analysis = useAppSelector((state: RootState) => state.analysis.analysisDetails.analysis);
	const defaultCalculationSettings = new DefaultCalculationSetting();
	const [isWashSalesOverlappingDays, setIsWashSalesOverlappingDays] = useState<boolean>(false);
	const [analysisSettingsValue, setAnalysisSettingsValue] = useState<string[]>([]);
	const [isWashSalesEnabled, setIsWashSalesEnabled] = useState<boolean>(false);
	const [showDividendOptions, setShowDividendOptions] = useState<boolean>(false);
	const [overrideDividendFactors, setOverrideDividendFactors] = useState<boolean>(true);
	const [clientType, setClientType] = useState<string>(ClientType.Investor);
	const [periodStartDate, setPeriodStartDate] = useState<Date>(defaultCalculationSettings.buildStartDate());
	const [periodEndDate, setPeriodEndDate] = useState<Date>(defaultCalculationSettings.buildEndDate());
	const [isDefaultSetting, setIsDefaultSetting] = useState<boolean>(false);
	const [isCharacterChecked, setIsCharacterChecked] = useState<boolean>(false);
	const [isIRC475Enabled, setIsIRC475Enabled] = useState<boolean>(false);
	const [isPaymentEnabled, setIsPaymentEnabled] = useState<boolean>(false);
	const [isStraddlesEnabled, setIsStraddlesEnabled] = useState<boolean>(false);
	const [isForcedConstructiveSales, setIsForcedConstructiveSales] = useState<string>(ForcedConstructiveSales.Yes);
	const [characterRadioCount, setCharacterRadioCount] = useState<string>(Character.SixtyFortySplit);
	const [ircRadioCount, setIRCRadioCount] = useState<string>(IRC475.FourSevenFiveF1);
	const [reliefMethod, setReliefMethod] = useState<string>(ReliefMethodOptions.FIFO);
	const [constructiveSalesChecked, setConstructiveSalesChecked] = useState<boolean>(false);
	const [isHedgingChecked, setIsHedgingChecked] = useState<boolean>(false);
	const [isOneToOneSelected, setIsOneToOneSelected] = useState<boolean>(false);
	const [isSameDaySelected, setIsSameDaySelected] = useState<boolean>(false);
	const [realizedReliefMethod, setRealizedReliefMethod] = useState<string>(ReliefMethodOptions.FIFO);
	const [offsetReliefMethod, setOffsetReliefMethod] = useState<string>(ReliefMethodOptions.FIFO);
	const [hedgingRadioCount, setHedgingRadioCount] = useState<string>(HedgingType.OneToAll);
	const [reportingRadioCount, setReportingRadioCount] = useState<string>(Reporting.Monthly);
	const [bifurcation, setBifurcation] = useState<string>(Bifurcation.Upload);
	const [accrualBasis, setAccrualBasis] = useState<string>(AccrualBasis.Accrual);
	const [isMTMEnabled, setIsMTMEnabled] = useState<boolean>(false);
	const [mtmSubSettings, setMTMSubSettings] = useState<MTMSubSetting[]>(MTMSubSettingDefault);

	const setCalculationGroupSettings = (setting: AnalysisSettingByJob) => {
		const settingsValue = JSON.parse(setting.settingValue) as AnalysisGroupValue;
		settingsValue.analysisSetting.forEach((settingsValue: AnalysisSettingsValue) => {
			switch (settingsValue.type) {
				case CalculationBaseGroup.PeriodFrom:
					setPeriodStartDate(new Date(settingsValue.settingValues[0]));
					break;
				case CalculationBaseGroup.PeriodTo:
					setPeriodEndDate(new Date(settingsValue.settingValues[0]));
					break;
				case CalculationBaseGroup.ClientType:
					setClientType(settingsValue.settingValues[0]);
					break;
				case CalculationBaseGroup.Reporting:
					setReportingRadioCount(settingsValue.settingValues[0]);
					break;
				case CalculationBaseGroup.Bifurcation:
					setBifurcation(settingsValue.settingValues[0]);
					break;
			}
		});
	};

	const setDefaultSetting = (analysis: Analysis[]) => {
		const analysisIDs: string[] = analysis.map((s) => s.analysisID.toString());
		setAnalysisSettingsValue(analysisIDs);
		setIsWashSalesEnabled(true);
		setShowDividendOptions(true);
		setIsCharacterChecked(true);
		setIsIRC475Enabled(true);
		setIsHedgingChecked(true);
		setConstructiveSalesChecked(true);
		setIsPaymentEnabled(true);
		setIsStraddlesEnabled(true);
		setIsDefaultSetting(true);
		setIsMTMEnabled(true);
	};

	const setSettingToFalse = () => {
		setReportingRadioCount(Reporting.Monthly);
		setClientType(ClientType.Investor);
		setPeriodStartDate(defaultCalculationSettings.buildStartDate());
		setPeriodEndDate(defaultCalculationSettings.buildEndDate());
		setIsWashSalesOverlappingDays(false);
		setIsWashSalesEnabled(false);
		setShowDividendOptions(false);
		setOverrideDividendFactors(true);
		setIsCharacterChecked(false);
		setIsIRC475Enabled(false);
		setIsPaymentEnabled(false);
		setIsStraddlesEnabled(false);
		setIsForcedConstructiveSales(ForcedConstructiveSales.Yes);
		setCharacterRadioCount(Character.SixtyFortySplit);
		setIRCRadioCount(IRC475.FourSevenFiveF1);
		setReliefMethod(ReliefMethodOptions.FIFO);
		setConstructiveSalesChecked(false);
		setIsHedgingChecked(false);
		setIsOneToOneSelected(false);
		setIsSameDaySelected(false);
		setRealizedReliefMethod(ReliefMethodOptions.FIFO);
		setOffsetReliefMethod(ReliefMethodOptions.FIFO);
		setHedgingRadioCount(HedgingType.OneToAll);
		setBifurcation(Bifurcation.Upload);
		setAccrualBasis(AccrualBasis.Accrual);
		setMTMSubSettings(MTMSubSettingDefault);
		setIsMTMEnabled(false);
	};

	const setAnalysisValue = (analysisSettingByJob: AnalysisSettingByJob): AnalysisGroupValue => {
		return JSON.parse(analysisSettingByJob.settingValue) as AnalysisGroupValue;
	};

	const setAnalysisSettingValue = (analysisSettingByJob: AnalysisSettingByJob[]): void => {
		let analysisValue: string[] = [];
		analysisSettingByJob.forEach((value: AnalysisSettingByJob) => {
			const settingsValue = JSON.parse(value.settingValue) as AnalysisGroupValue;
			if (settingsValue.analysis) {
				(settingsValue.analysis as string[]).forEach((analysis: string) => {
					analysisValue.push(analysis);
				});
			}
		});
		setAnalysisSettingsValue(analysisValue);
	};

	const setWashSalesGroupSettings = (setting: AnalysisSettingByJob) => {
		const settingsValue = setAnalysisValue(setting);
		setIsWashSalesOverlappingDays(JSON.parse(settingsValue.analysisSetting[0].settingValues[0]));
		setIsWashSalesEnabled(true);
	};

	const setDividendGroupSettings = (setting: AnalysisSettingByJob) => {
		const settingsValue = setAnalysisValue(setting);
		settingsValue.analysisSetting.forEach((analysisSettingsValue: AnalysisSettingsValue) => {
			switch (analysisSettingsValue.type) {
				case Dividend.OverridDividendFactors:
					setOverrideDividendFactors(JSON.parse(analysisSettingsValue.settingValues[0]));
					break;
				case Dividend.AccrualBasis:
					setAccrualBasis(analysisSettingsValue.settingValues[0]);
					break;
				default:
					break;
			}
			setShowDividendOptions(true);
		});
	};

	const setConstructiveSalesAnalysis = (setting: AnalysisSettingByJob) => {
		const settingsValue = setAnalysisValue(setting);
		settingsValue.analysisSetting.forEach((analysisSettingsValue: AnalysisSettingsValue) => {
			switch (analysisSettingsValue.type) {
				case ConstructiveSales.ForcedConstructiveSales:
					setIsForcedConstructiveSales(analysisSettingsValue.settingValues[0]);
					break;
				case ConstructiveSales.ReliefMethodology:
					setReliefMethod(analysisSettingsValue.settingValues[0]);
					break;
				default:
					break;
			}
			setConstructiveSalesChecked(true);
		});
	};

	const setOneTwoFiveSixAnalysis = (setting: AnalysisSettingByJob) => {
		const settingsValue = setAnalysisValue(setting);
		settingsValue.analysisSetting.forEach((analysisSettingsValue: AnalysisSettingsValue) => {
			setCharacterRadioCount(analysisSettingsValue.settingValues[0]);
		});
		setIsCharacterChecked(true);
	};
    const setIRC475Analysis = (setting: AnalysisSettingByJob) => {
		const settingsValue = setAnalysisValue(setting);
		settingsValue.analysisSetting.forEach((analysisSettingsValue: AnalysisSettingsValue) => {
			setIRCRadioCount(analysisSettingsValue.settingValues[0]);
		});
		setIsIRC475Enabled(true);
	};
	const setPaymentEnabled = () => {
		setIsPaymentEnabled(true);
	};

	const setStraddlesEnabled = () => {
		setIsStraddlesEnabled(true);
	};

	const setHedgingAnalysis = (setting: AnalysisSettingByJob) => {
		const settingsValue = setAnalysisValue(setting);
		settingsValue.analysisSetting.forEach((analysisSettingsValue: AnalysisSettingsValue) => {
			switch (analysisSettingsValue.type) {
				case Hedging.HedgingType:
					setHedgingRadioCount(analysisSettingsValue.settingValues[0]);
					setIsOneToOneSelected(HedgingType.OneToOne === analysisSettingsValue.settingValues[0]);
					break;
				case Hedging.OffsetRelief:
					setOffsetReliefMethod(analysisSettingsValue.settingValues[0]);
					break;
				case Hedging.RealizedRelief:
					setRealizedReliefMethod(analysisSettingsValue.settingValues[0]);
					break;
				case Hedging.SameDayHedging:
					setIsSameDaySelected(JSON.parse(analysisSettingsValue.settingValues[0]));
					break;
				default:
					break;
			}
			setIsHedgingChecked(true);
		});
	};

	const setMTMSettings = (setting: AnalysisSettingByJob) => {
		const settingsValue = setAnalysisValue(setting);
		const mtmSettings: MTMSubSetting[]= [];
		settingsValue.analysisSetting.map((analysisSettingsValue: AnalysisSettingsValue) => {
			const mtmSetting: MTMSubSetting = {
				name : analysisSettingsValue.type,
				value: JSON.parse(analysisSettingsValue.settingValues[0])
			}
			mtmSettings.push(mtmSetting);
		});
		setMTMSubSettings(mtmSettings);
		setIsMTMEnabled(true);
	};
	
	const setJobSettings = (jobId: Guid) => {
		setSettingToFalse();
		fetchAnalysisSettingsByJob(jobId).then(function (result: ResponseData) {
			if (!result.Success) {
				showErrorSnackbar(dispatch, Messages.EXCEPTION_FOR_FETCH);
				return;
			}
			const data = JSON.parse(result.Data.toString()) as AnalysisSettingByJob[];
			if (data.length === 0) {
				setDefaultSetting(analysis);
				return;
			}
			setIsDefaultSetting(false);
			setAnalysisSettingValue(data);
			data.forEach(function (analysisSetting) {
				switch (analysisSetting.analysisGroup) {
					case AnalysisGroup.Dividend:
						setDividendGroupSettings(analysisSetting);
						break;
					case AnalysisGroup.WashSales:
						setWashSalesGroupSettings(analysisSetting);
						break;
					case AnalysisGroup.ConstructiveSales:
						setConstructiveSalesAnalysis(analysisSetting);
						break;
					case AnalysisGroup.Character:
						setOneTwoFiveSixAnalysis(analysisSetting);
						break;
					case AnalysisGroup.Hedging:
						setHedgingAnalysis(analysisSetting);
						break;
					case AnalysisGroup.Calculation:
						setCalculationGroupSettings(analysisSetting);
						break;
					case AnalysisGroup.Payments:
						setPaymentEnabled();
						break;
					case AnalysisGroup.Straddles:
						setStraddlesEnabled();
						break;
					case AnalysisGroup.IRC475:
						setIRC475Analysis(analysisSetting);
						break;
					case AnalysisGroup.MTM:
						setMTMSettings(analysisSetting);
				}
			});
		});
	};

	return (
		<AnalysisContext.Provider
			value={{
				setJobSettings,
				isWashSalesOverlappingDays,
				setIsWashSalesOverlappingDays,
				analysisSettingsValue,
				setAnalysisSettingsValue,
				isWashSalesEnabled,
				setIsWashSalesEnabled,
				showDividendOptions,
				setShowDividendOptions,
				overrideDividendFactors,
				setOverrideDividendFactors,
				clientType,
				setClientType,
				periodStartDate,
				setPeriodStartDate,
				periodEndDate,
				setPeriodEndDate,
				isDefaultSetting,
				setIsDefaultSetting,
				isCharacterChecked,
				setIsCharacterChecked,
				isIRC475Enabled,
				setIsIRC475Enabled,
				isForcedConstructiveSales: isForcedConstructiveSales,
				setIsForcedConstructiveSales: setIsForcedConstructiveSales,
				constructiveSalesChecked,
				setConstructiveSalesChecked,
				isHedgingChecked: isHedgingChecked,
				setIsHedgingChecked: setIsHedgingChecked,
				isOneToOneSelected,
				setIsOneToOneSelected,
				characterRadioCount,
				setCharacterRadioCount,
				ircRadioCount,
				setIRCRadioCount,
				reliefMethod,
				setReliefMethod,
				hedgingRadioCount: hedgingRadioCount,
				setHedgingRadioCount: setHedgingRadioCount,
				reportingRadioCount,
				setReportingRadioCount,
				realizedReliefMethod,
				setRealizedReliefMethod,
				offsetReliefMethod,
				setOffsetReliefMethod,
				isPaymentEnabled,
				setIsPaymentEnabled,
				isStraddlesEnabled,
				setIsStraddlesEnabled,
				bifurcation,
				setBifurcation,
				accrualBasis,
				setAccrualBasis,
				isMTMEnabled,
				setIsMTMEnabled,
				mtmSubSettings,
				setMTMSubSettings,
				isSameDaySelected,
				setIsSameDaySelected
			}}
		>
			{children}
		</AnalysisContext.Provider>
	);
};

export default AnalysisContextProvider;
