import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Guid } from 'guid-typescript';
import { AppDispatch, RootState } from '../../../app/store';
import { Messages } from '../../../Constants/Messages';
import { showErrorSnackbar } from '../../../component/snackbar/Snackbar.slice'
import { ResponseData } from '../../../api/ApiFetch/ApiResponse';
import { getFavoriteJobsApi } from '../../../api/FavoriteJobs/favoriteJobsController.api';
import { Jobs } from '../Home.types';

interface State {
    favoriteJobIds: Guid[];
    entityTypeTab: Jobs;
}

const initialState: State = {
    favoriteJobIds: [],
    entityTypeTab: Jobs.AllJobs
};

export const fetchFavoriteJobsData = createAsyncThunk<
    void,
    void,
    {
        dispatch: AppDispatch;
        state: RootState;
    }
>('settingsData/fetchSettingsData', async (_: void, thunkAPI) => {
    await getFavoriteJobsApi().then(function (result: ResponseData) {
        if (!result.Success) {
            showErrorSnackbar(thunkAPI.dispatch, Messages.EXCEPTION_FOR_FETCH);
            return;
        }
        const data = JSON.parse(result.Data.toString()) as Guid[];
        thunkAPI.dispatch(setFavoriteJobsData(data));
    });
});

const favoriteSlice = createSlice({
    name: 'FavoriteJob',
    initialState,
    reducers: {
        setFavoriteJobsData: (
            state,
            action: PayloadAction<Guid[]>
        ) => {
            return {...state, favoriteJobIds: action.payload};
        },
        setShowFavoriteEntities: (
            state,
            action: PayloadAction<number>
        ) => {
            return {...state, entityTypeTab: action.payload};
        }
    },
});

export const {setFavoriteJobsData, setShowFavoriteEntities} = favoriteSlice.actions;

export default favoriteSlice.reducer;