import { ApiUrls } from '../ApiConstants/ApiUrls';
import { FetchApi } from '../ApiFetch/ApiFetch';
import { CreateTextResponse, ResponseData } from '../ApiFetch/ApiResponse';
import { HTTPMethod } from '../ApiFetch/HTTPMethod.types';
import { ServiceType } from '../ApiFetch/ServiceType.types';

export const createStatusDetail = async (data: any): Promise<ResponseData> => {
    const formattedUrl = ApiUrls.CREATE_STATUS_DETAIL.replace('{jobId}', data.JobId);
    const response = await FetchApi({
        endpoint: formattedUrl,
        method: HTTPMethod.Post,
        headers: {
            'Content-Type': 'application/json'
        },
        apiType: ServiceType.StatusWidget,
        body: JSON.stringify(data)
    }).catch((error) => {
        return error;
    });
    return CreateTextResponse(response);
};

export const getStatusDetail = async (jobId: any): Promise<ResponseData> => {
    const formattedUrl = ApiUrls.GET_STATUS_DETAIL.replace('{jobId}', jobId);
    const response = await FetchApi({
        endpoint: formattedUrl,
        method: HTTPMethod.Get,
        apiType: ServiceType.StatusWidget,
    }).catch((error) => {
        return error;
    });
    return CreateTextResponse(response);
};


