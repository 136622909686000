import { EventType } from '../component/status-widget/types/Event.types';

export const GetStatusWidgetEventMessage = (status: EventType) => {
    let message = '';
    switch (status) {
        case EventType.JobLockedFinalized:
            message = 'Work item finalized successfully';
            break;
        case EventType.JobLockFinalizedFail:
            message = 'Work item finalize failed';
            break;
        case EventType.JobUnlocked:
            message = 'Work item unlocked successfully';
            break;
        case EventType.JobUnlockFail:
            message = 'Work item unlock failed';
            break;
    }
    return message;
}