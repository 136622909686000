import { combineReducers, configureStore } from '@reduxjs/toolkit';
import generalActionsReducer from '../component/TopMenu/GlobalActions/GlobalActions.slice';
import assetOfInterestSliceReducer from '../pages/admin/assetsOfInterest/AssetOfInterest.Slice';
import snackbarSlice from '../component/snackbar/Snackbar.slice';
import uploadImportReducer from '../component/UploadDialog/UploadDialog.slice';
import cemReducer from '../core/Slices/Cem.slice';
import breadcrumbNavigationReducer from '../component/bread-crumb-navigation/BreadcrumbNavigation.slice';
import sideMenuReducer from '../component/sideMenuComponent/SideMenuFeature.slice';
import datasetReducer from '../core/Slices/Dataset.slice';
import notificationsSlice from '../core/Slices/Notifications.slice';
import storageSession from 'redux-persist/lib/storage/session';
import referenceTableDataSlice from '../pages/admin/Lists/ReferenceTable/ReferenceTable.slice';
import { persistReducer, persistStore } from 'redux-persist';
import { envProperties } from '../core/Utils/EnvProperties';
import inputDetailsSlice from '../pages/input/inputDetails/InputDetails.slice';
import calculationRun from '../component/TopMenu/CalculationRun.slice';
import analysisSlice from '../component/Settings/AnalysisSettings.slice';
import statusWidgetReducer from '../component/status-widget/statusWidget.slice';
import favoriteSlice from '../pages/home/entity/FavoriteEntity.slice';

const isDevToolsEnabled = envProperties?.IsDevToolsEnabled;
const persistConfig = {
    key: 'root',
    storage: storageSession,
};

const combineReducer = combineReducers({
    assetOfInterestInput: assetOfInterestSliceReducer,
    breadcrumbNavigation: breadcrumbNavigationReducer,
    cem: cemReducer,
    dataset: datasetReducer,
    generalActions: generalActionsReducer,
    notifications: notificationsSlice,
    snackbar: snackbarSlice,
    sideMenuActions: sideMenuReducer,
    uploadImport: uploadImportReducer,
    calculationRun: calculationRun,
    referenceTable: referenceTableDataSlice,
    inputData: inputDetailsSlice,
    analysis: analysisSlice,
    statusWidget: statusWidgetReducer,
    favoriteJobs: favoriteSlice
});

const persistedReducer = persistReducer(persistConfig, combineReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
    devTools: isDevToolsEnabled ? Boolean(JSON.parse(envProperties.IsDevToolsEnabled)) : false,
});

export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
