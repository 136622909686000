import { ResponseData } from '../../api/ApiFetch/ApiResponse';
import { LibraryRetrived, RetriveEnrichmentSettings, WarehouseType } from './Settings.types';

export const convertJsonToLibrariesRetrived = (response: ResponseData, warehouseType: WarehouseType): LibraryRetrived[] => {
    let libraryRetrived = JSON.parse(response.Data.toString()) as LibraryRetrived[];
    return libraryRetrived.filter(l => l.IsActive).map(function (l) {
        l.Type = warehouseType;
        return l;
    });
}

export const convertJsonToPascalCase = (response: ResponseData): string => {
    let jsonData = response.Data.toString();
    jsonData = jsonData.replaceAll('"fileName"', '"FileName"')
        .replaceAll('"id"', '"Id"')
        .replaceAll('"isActive"', '"IsActive"')
        .replaceAll('"name"', '"Name"')
        .replaceAll('"importStatus"', '"ImportStatus"')
        .replaceAll('"modifiedBy"', '"ModifiedBy"')
        .replaceAll('"modifiedDate"', '"ModifiedDate"');
    return jsonData;
}

export const convertJsonToLibrariesClientReferencePascalCase = (response: ResponseData, warehouseType: WarehouseType): LibraryRetrived[] => {
    let jsonData = convertJsonToPascalCase(response);
    jsonData = jsonData.replaceAll('"fileName"', '"FileName"')
        .replaceAll('"id"', '"Id"')
        .replaceAll('"isActive"', '"IsActive"')
        .replaceAll('"name"', '"Name"');
    let libraryRetrived = JSON.parse(jsonData) as LibraryRetrived[];
    return libraryRetrived.filter(l => l.IsActive).map(function (l) {
        l.Type = warehouseType;
        return l;
    });
}

export const convertJsonToLibrariesLibraryValuePascalCase = (response: ResponseData): RetriveEnrichmentSettings[] => {
    let jsonData = response.Data.toString();
    jsonData = jsonData.replaceAll('"libraryType"', '"LibraryType"')
        .replaceAll('"libraryValue"', '"LibraryValue"');
    let libraryRetrived = JSON.parse(jsonData) as RetriveEnrichmentSettings[];
    return libraryRetrived;
}