
export interface SnackbarMessage {
    message: string;
    type: SnackbarType;
    duration: number;
    key: number;
}

export enum SnackbarType {
    Info = 'snackbar-info',
    Error = 'snackbar-error',
    Success = 'snackbar-success',
    Warning = 'snackbar-warning'
}