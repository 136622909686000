import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import { createTheme } from '@material-ui/core';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { persistor, store } from './app/store';
import { PersistGate } from 'redux-persist/integration/react';

export const history = createBrowserHistory();
export const theme = createTheme({
	typography: {
		h1: {
			fontSize: '14px',
			fontWeight: 500,
			lineHeight: 1.2,
			color: '#2d2d2d',
			textTransform: 'uppercase',
		},
		h2: {
			fontSize: '16px',
			fontWeight: 500,
			lineHeight: 1.2,
			color: '#2d2d2d',
		},
		body1: { color: '#2d2d2d', fontSize: '13px' },
	},
	palette: { primary: { main: '#0093de' } },
	props: { MuiPaper: { square: true } },
});

ReactDOM.render(
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<BrowserRouter>	
					<App />
				</BrowserRouter>
			</PersistGate>
		</Provider>
	,
	document.getElementById('root'),
);
