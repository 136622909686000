import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { uploadFormData } from './UploadDialog.api';
import { UploadImportFormData } from './UploadImportFormData.types';
import { AppDispatch, RootState } from '../../app/store';
import { setDatasetDetails } from '../../core/Slices/Dataset.slice';
import { DatasetDetailsResponse, GetDisplayMessageForImportStatus } from '../../core/Types/DatasetDetailsResponse.types';
import { Messages } from '../../Constants/Messages';
import { showErrorSnackbar, showSuccessSnackbar } from '../../component/snackbar/Snackbar.slice';
import { handleGetStatusDetail } from '../../component/status-widget/statusWidget.slice';

interface State {
	isUploadDialogVisible: boolean;
}

const initialState: State = {
	isUploadDialogVisible: false,
};

export const uploadImportFormData = createAsyncThunk<
	void,
	UploadImportFormData,
	{
		dispatch: AppDispatch;
		state: RootState;
	}
>('uploadImport/uploadImportFormData', async (params: UploadImportFormData, thunkAPI) => {
	const response = await uploadFormData(params);
	if (!response.ok) {
		showErrorSnackbar(
			thunkAPI.dispatch,
			Messages.UPLOAD_FILE_FAILED
		);
		return;
	}

	const datasetDetails = JSON.parse(await response.text()) as DatasetDetailsResponse
	thunkAPI.dispatch(setDatasetDetails(datasetDetails))
	thunkAPI.dispatch(uploadDialogClosed());
	const displayMessage = GetDisplayMessageForImportStatus(datasetDetails.status);
	thunkAPI.dispatch(handleGetStatusDetail(params.jobId));

	if (displayMessage) {
		showSuccessSnackbar(
			thunkAPI.dispatch,
			displayMessage
		)
	} else {
		showErrorSnackbar(
			thunkAPI.dispatch,
			Messages.UPLOAD_FILE_FAILED
		);
	}
});

const uploadImportSlice = createSlice({
	name: 'uploadImport',
	initialState,
	reducers: {
		uploadDialogOpened(): State {
			return { ...initialState, isUploadDialogVisible: true };
		},
		uploadDialogClosed(): State {
			return { ...initialState };
		},
	},
});

export const { uploadDialogOpened, uploadDialogClosed } = uploadImportSlice.actions;

export default uploadImportSlice.reducer;
