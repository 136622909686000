import { TextStrings } from '../../Constants/TextStrings';

export interface ResponseData {
    StatusCode: number;
    Data: string | Blob;
    StatusMessage: string;
    Success: boolean;
    ContentType: string | undefined;
}

export const CreateTextResponse = async (apiResponse: Response): Promise<ResponseData> => {
    if (apiResponse.ok === undefined) {
        return CreateServerUnavailableExceptionResponse(apiResponse);
    }
    const response: ResponseData = {
        StatusCode: apiResponse.status,
        Data: await apiResponse.text(),
        StatusMessage: apiResponse.statusText,
        Success: apiResponse.ok,
        ContentType: apiResponse.headers.get('content-type')?.toString()
    }
    return response;
}

export const CreateBlobResponse = async (apiResponse: Response): Promise<ResponseData> => {
    if (apiResponse.ok === undefined) {
        return CreateServerUnavailableExceptionResponse(apiResponse);
    }
    const response: ResponseData = {
        StatusCode: apiResponse.status,
        Data: await apiResponse.blob(),
        StatusMessage: apiResponse.statusText,
        Success: apiResponse.ok,
        ContentType: apiResponse.headers.get(TextStrings.CONTENT_TYPE)?.toString()
    }
    return response;
}

export const CreateServerUnavailableExceptionResponse = async (apiResponse: Response): Promise<ResponseData> => {
    const response: ResponseData = {
        StatusCode: 503,
        Data: TextStrings.SERVER_UNAVAILABLE,
        StatusMessage: TextStrings.ERROR_OCCURRED,
        Success: false,
        ContentType: ''
    }
    return response;
}