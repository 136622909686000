import { ClientReferenceUploadMessage, IconGroup } from '../Types/Notifications.types';
import { AppDispatch } from '../../app/store';
import { addNotification, addUserNotification } from '../Slices/Notifications.slice';
import { Messages } from '../../Constants/Messages';
import { setReferenceFileUpload } from '../../pages/admin/Lists/ReferenceTable/ReferenceTable.slice';

export function addClientReferenceSuccessNotification(
	clientReferenceUploadMessage: ClientReferenceUploadMessage,
	dispatch: AppDispatch,
) {
	dispatch(
		addNotification({
			type: IconGroup.ClientReference,
			user: clientReferenceUploadMessage.uploadedBy,
			dateTime: clientReferenceUploadMessage.uploadedOn,
			message: Messages.REFERENCE_TABLE_UPDATE_SUCCESS,
			groupId: clientReferenceUploadMessage.groupId,
		}),
	);
	dispatch(setReferenceFileUpload(true));
}

export function addClientReferenceFailureNotification(
	clientReferenceUploadMessage: ClientReferenceUploadMessage,
	dispatch: AppDispatch,
) {
	dispatch(
		addNotification({
			type: IconGroup.ClientReference,
			user: clientReferenceUploadMessage.uploadedBy,
			dateTime: clientReferenceUploadMessage.uploadedOn,
			message: Messages.REFERENCE_TABLE_UPDATE_FAILED,
			groupId: clientReferenceUploadMessage.groupId,
		}),
	);
	dispatch(setReferenceFileUpload(true));
}

export function addUserClientReferenceSuccessNotification(
	clientReferenceUploadMessage: ClientReferenceUploadMessage,
	dispatch: AppDispatch,
) {
	dispatch(
		addUserNotification({
			type: IconGroup.ClientReference,
			user: clientReferenceUploadMessage.uploadedBy,
			dateTime: clientReferenceUploadMessage.uploadedOn,
			message: Messages.REFERENCE_TABLE_UPDATE_SUCCESS,
			groupId: clientReferenceUploadMessage.groupId,
		}),
	);
	dispatch(setReferenceFileUpload(true));
}

export function addUserClientReferenceFailureNotification(
	clientReferenceUploadMessage: ClientReferenceUploadMessage,
	dispatch: AppDispatch,
) {
	dispatch(
		addUserNotification({
			type: IconGroup.ClientReference,
			user: clientReferenceUploadMessage.uploadedBy,
			dateTime: clientReferenceUploadMessage.uploadedOn,
			message: Messages.REFERENCE_TABLE_UPDATE_FAILED,
			groupId: clientReferenceUploadMessage.groupId,
		}),
	);
	dispatch(setReferenceFileUpload(true));
}
