import { ClientReferenceUploadMessage, IconGroup } from '../Types/Notifications.types';
import { AppDispatch } from '../../app/store';
import { addNotification, addUserNotification } from '../Slices/Notifications.slice';
import { Messages } from '../../Constants/Messages';
import { handleGetStatusDetail } from '../../component/status-widget/statusWidget.slice';
import { Guid } from 'guid-typescript';

export function addClientRulesetSuccessNotification(
    clientReferenceUploadMessage: ClientReferenceUploadMessage,
    dispatch: AppDispatch,
) {
    dispatch(
        addNotification({
            type: IconGroup.ClientReference,
            user: clientReferenceUploadMessage.uploadedBy,
            dateTime: clientReferenceUploadMessage.uploadedOn,
            message: Messages.RULESET_UPDATED_SUCCESSFULLY,
            groupId: clientReferenceUploadMessage.groupId,
        }),
    );
    dispatch(handleGetStatusDetail(Guid.parse(clientReferenceUploadMessage.groupId)));
}

export function addClientRulesetFailureNotification(
    clientReferenceUploadMessage: ClientReferenceUploadMessage,
    dispatch: AppDispatch,
) {
    dispatch(
        addNotification({
            type: IconGroup.ClientReference,
            user: clientReferenceUploadMessage.uploadedBy,
            dateTime: clientReferenceUploadMessage.uploadedOn,
            message: Messages.RULESET_UPDATE_FAILED,
            groupId: clientReferenceUploadMessage.groupId,
        }),
    );
    dispatch(handleGetStatusDetail(Guid.parse(clientReferenceUploadMessage.groupId)));
}

export function addUserClientRulesetSuccessNotification(
    clientReferenceUploadMessage: ClientReferenceUploadMessage,
    dispatch: AppDispatch,
) {
    dispatch(
        addUserNotification({
            type: IconGroup.ClientReference,
            user: clientReferenceUploadMessage.uploadedBy,
            dateTime: clientReferenceUploadMessage.uploadedOn,
            message: Messages.RULESET_UPDATED_SUCCESSFULLY,
            groupId: clientReferenceUploadMessage.groupId,
        }),
    );
}

export function addUserClientRulesetFailureNotification(
    clientReferenceUploadMessage: ClientReferenceUploadMessage,
    dispatch: AppDispatch,
) {
    dispatch(
        addUserNotification({
            type: IconGroup.ClientReference,
            user: clientReferenceUploadMessage.uploadedBy,
            dateTime: clientReferenceUploadMessage.uploadedOn,
            message: Messages.RULESET_UPDATE_FAILED,
            groupId: clientReferenceUploadMessage.groupId,
        }),
    );
}