import { SettingsType } from '../../core/Types/Notifications.types';

export const getSettingName = (type: SettingsType) => {
	const analysis = 'Analysis';
	const libraries = 'Libraries';
	switch (type) {
		case SettingsType.Analysis:
			return analysis;
		case SettingsType.Library:
			return libraries;
		default:
			return '';
	}
};
