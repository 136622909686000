export enum TextStrings {
	JOB_ID = 'jobId',
	CLIENT_ID = 'clientId',
	CONTENT_TYPE = 'content-type',
	SERVER_UNAVAILABLE = 'Server unavailable',
	ERROR_OCCURRED = 'Error occurred',
	SUCCESS = 'Success',
	PROCESSING = 'Processing',
	FAILED = 'Failed',
	ALL_ENTITIES = 'All Jobs',
	FAVORITE_ENTITIES = 'Favorite Jobs'
}
