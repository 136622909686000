import { Guid } from 'guid-typescript';
import { FetchConnectedJobDetails, ImportFileFormData, UpdateFileFormData } from '../../component/ImportDialog/ImportDialog.types';
import { ApiUrls } from '../ApiConstants/ApiUrls';
import { FetchApi } from '../ApiFetch/ApiFetch';
import { CreateBlobResponse, CreateTextResponse, ResponseData } from '../ApiFetch/ApiResponse';
import { HTTPMethod } from '../ApiFetch/HTTPMethod.types';
import { ServiceType } from '../ApiFetch/ServiceType.types';

export const getClientReferenceLibrary = async (clientId: string): Promise<ResponseData> => {
    const url = ApiUrls.GET_CLIENT_REFERENCE_LIBRARY.replace('{clientId}', clientId);
    const response = await FetchApi({
        endpoint: url,
        method: HTTPMethod.Get,
        apiType: ServiceType.Enrichment
    }).catch((error) => {
        return error;
    });
    return CreateTextResponse(response);
};

export const uploadReferenceTableFile = async (formRequest: ImportFileFormData): Promise<ResponseData> => {
    const formData = commonFormData(formRequest);
    const url = ApiUrls.UPLOAD_REFERENCE_FILE.replace('{clientId}', formRequest.clientId);
    const response = await FetchApi({
        endpoint: url,
        method: HTTPMethod.Post,
        apiType: ServiceType.Enrichment,
        body: formData,
    }).catch((error) => {
        return error;
    });
    return CreateTextResponse(response);
};

export const updateReferenceTableFile = async (formRequest: UpdateFileFormData): Promise<ResponseData> => {
    const url = ApiUrls.UPDATE_REFERENCE_FILE
        .replace('{clientId}', formRequest.clientId)
        .replace('{id}', formRequest.id);
    const formData = new FormData();
    formData.append('Name', formRequest.name);
    formData.append('IsActive', `${formRequest.isActive}`);
    let response = await FetchApi({
        endpoint: url,
        method: HTTPMethod.Put,
        apiType: ServiceType.Enrichment,
        body: formData,
    }).catch((error) => {
        return error;
    });
    return CreateTextResponse(response);
};

export const downloadReferenceTableFile = async (
    formRequest: ImportFileFormData,
    clientId: string,
): Promise<ResponseData> => {
    const url = ApiUrls.DOWNLOAD_REFERENCE_FILE.replace('{clientId}', clientId)
        .replace('{id}', formRequest.id)
        .replace('{type}', formRequest.fileType);
    const response = await FetchApi({
        endpoint: url,
        method: HTTPMethod.Get,
        apiType: ServiceType.Enrichment,
    }).catch((error) => {
        return error;
    });
    return CreateBlobResponse(response);
};

const commonFormData = (referenceTableImportFormData: ImportFileFormData) => {
    const formData = new FormData();
    formData.append('File', referenceTableImportFormData.file);
    formData.append('Name', referenceTableImportFormData.listName);
    formData.append('IsActive', `${referenceTableImportFormData.isActive}`);
    formData.append(
        'ClientReferenceLibraryId',
        `${referenceTableImportFormData.id === '' ? Guid.EMPTY : referenceTableImportFormData.id}`,
    );
    return formData;
};

export const getConnectedJobsForClientReferenceTable = async (formRequest: FetchConnectedJobDetails): Promise<ResponseData> => {
    const url = ApiUrls.CHECK_CONNECTED_JOBS_FOR_CLIENT_REFERENCE_TABLES
    .replace('{clientId}', formRequest.clientId.toString())
    .replace('{id}', formRequest.libraryId.toString());
    const response = await FetchApi({
        endpoint: url,
        method: HTTPMethod.Get,
        apiType: ServiceType.Enrichment,
    }).catch((error) => {
        return error;
    });
    return CreateTextResponse(response);
};
