import { createSlice } from '@reduxjs/toolkit';

interface State {
	isBackdropVisible: boolean;
	isInputSectionVisible: boolean;
	isOutputSectionVisible: boolean;
	isAdminSectionVisible: boolean;
	isUserSettingsModalVisible: boolean;
}

const initialState: State = {
	isBackdropVisible: false,
	isInputSectionVisible: false,
	isOutputSectionVisible: false,
	isAdminSectionVisible: false,
	isUserSettingsModalVisible: false,
};

const sideMenuFeatureSlice = createSlice({
	name: 'sideMenuSlice',
	initialState,
	reducers: {
		handleClose(): State {
			return { ...initialState };
		},
		handleOpenInputsSection(): State {
			return {
				...initialState,
				isBackdropVisible: true,
				isInputSectionVisible: true,
				isOutputSectionVisible: false,
				isAdminSectionVisible: false,
				isUserSettingsModalVisible: false,
			};
		},
		handleOpenOutputSection(): State {
			return {
				...initialState,
				isBackdropVisible: true,
				isInputSectionVisible: false,
				isOutputSectionVisible: true,
				isAdminSectionVisible: false,
				isUserSettingsModalVisible: false,
			};
		},
		handleOpenAdminSection(): State {
			return {
				...initialState,
				isBackdropVisible: true,
				isInputSectionVisible: false,
				isOutputSectionVisible: false,
				isAdminSectionVisible: true,
				isUserSettingsModalVisible: false,
			};
		},
		handleOpenUserSettingsModal(): State {
			return {
				...initialState,
				isBackdropVisible: true,
				isInputSectionVisible: false,
				isOutputSectionVisible: false,
				isAdminSectionVisible: false,
				isUserSettingsModalVisible: true,
			};
		},
	},
});

export const {
	handleClose,
	handleOpenAdminSection,
	handleOpenInputsSection,
	handleOpenOutputSection,
	handleOpenUserSettingsModal,
} = sideMenuFeatureSlice.actions;

export default sideMenuFeatureSlice.reducer;
