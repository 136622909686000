import { Messages } from '../../Constants/Messages';
import { IconGroup, SettingsNotification } from '../Types/Notifications.types';
import { addNotification, addUserNotification } from '../Slices/Notifications.slice';
import { AppDispatch } from '../../app/store';
import { getSettingName } from '../../component/Settings/Settings.utils';

export function addSettingsSuccessNotification(settingsSuccessMessage: SettingsNotification, dispatch: AppDispatch) {
	dispatch(
		addNotification({
			type: IconGroup.Settings,
			user: settingsSuccessMessage.startedBy,
			dateTime: settingsSuccessMessage.completedOn,
			message: getSettingName(settingsSuccessMessage.type) + ' ' + Messages.SETTINGS_SAVED_SUCCESSFULLY,
			groupId: settingsSuccessMessage.groupId,
		}),
	);
}

export function addUserSettingsSuccessNotification(
	settingsSuccessMessage: SettingsNotification,
	dispatch: AppDispatch,
) {
	dispatch(
		addUserNotification({
			type: IconGroup.Settings,
			user: settingsSuccessMessage.startedBy,
			dateTime: settingsSuccessMessage.completedOn,
			message: getSettingName(settingsSuccessMessage.type) + ' ' + Messages.SETTINGS_SAVED_SUCCESSFULLY,
			groupId: settingsSuccessMessage.groupId,
		}),
	);
}

export function addSettingsFailureNotification(settingsFailedMessage: SettingsNotification, dispatch: AppDispatch) {
	dispatch(
		addNotification({
			type: IconGroup.Settings,
			user: settingsFailedMessage.startedBy,
			dateTime: settingsFailedMessage.completedOn,
			message: getSettingName(settingsFailedMessage.type) + ' ' + Messages.SETTINGS_SAVE_FAILED,
			groupId: settingsFailedMessage.groupId,
		}),
	);
}
