
export enum ContrastPolarity {
	LightMode,
	DarkMode,
}

export enum ApplicationTheme {
	White,
	Gray,
	Blue,
	Green,
	Olive,
	Orange,
	Purple,
	Teal,
}

export enum GridDataDensity {
	Compact,
	Medium,
	Spacious,
}

export const setPrimaryColor = (applicationTheme: ApplicationTheme): string => {
	switch (applicationTheme) {
		case ApplicationTheme.White:
			return '#0093de';
		case ApplicationTheme.Gray:
			return '#63666a';
		case ApplicationTheme.Blue:
			return '#33afe4';
		case ApplicationTheme.Green:
			return '#3f9c35';
		case ApplicationTheme.Olive:
			return '#a2a569';
		case ApplicationTheme.Orange:
			return '#e87722';
		case ApplicationTheme.Purple:
			return '#9f5cc0';
		case ApplicationTheme.Teal:
			return '#34a798';
		default:
			return '#0093de';
	}
};
