import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Guid } from 'guid-typescript';
import { getStatusDetail } from '../../api/statusWidget/statusDetailController.api';
import { AppDispatch, RootState } from '../../app/store';
import {
	CalculationFailedNotificationPayload,
	CalculationSuccessfulNotificationPayload,
	ImportNotification,
	Status,
	StatusDetailResponse,
} from './types/StatusTracker.types';
import StatusCode from 'status-code-enum';
import { StatusWidgetMessage } from '../../core/Types/Notifications.types';

const initialState: StatusDetailResponse = {
	currentlyWith: '',
	id: Guid.createEmpty(),
	isFinalized: false,
	isGreyedOut: false,
	itemId: '',
	modifiedDate: new Date(),
	status: 0,
	reviewedBy: '',
	hierarchyLevelId: Guid.createEmpty(),
};

export const handleGetStatusDetail = createAsyncThunk<
	void,
	Guid,
	{
		dispatch: AppDispatch;
		state: RootState;
	}
>('statusWidget/handleGetStatusDetail', async (jobId: Guid, thunkAPI) => {
	if (jobId) {
		await getStatusDetail(jobId).then(function (result) {
			if (result.Success) {
				const data = JSON.parse(result.Data.toString()) as StatusDetailResponse;
				thunkAPI.dispatch(setDetail(data));
				return;
			}
			else if (result.StatusCode === StatusCode.ClientErrorNotFound) {
				thunkAPI.dispatch(setDetail(initialState));
				return;
			}
		});
	}
});

export const statusWidgetSlice = createSlice({
	name: 'statusWidget',
	initialState,
	reducers: {
		setDetail: (state, action: PayloadAction<StatusDetailResponse>) => {
			return { ...action.payload };
		},
		resetDetail: (state) => {
			return { ...initialState };
		},
		setTemporaryImportNotificationStatusWidgetState: (state, action: PayloadAction<ImportNotification>) => {
			if (
				action.payload.hierarchyLevelId.toString().toLowerCase() !==
				state.hierarchyLevelId.toString().toLowerCase()
			) {
				return state;
			}
			return {
				...state,
				currentlyWith: action.payload.currentlyWith,
				lastChange: action.payload.lastChange,
				reviewedBy: '',
				latestChangeDate: action.payload.lastChange,
				status: Status.Doing
			};
		},
		setTemporarySuccessfulCalculationNotificationStatusWidgetState: (
			state,
			action: PayloadAction<CalculationSuccessfulNotificationPayload>,
		) => {
			if (
				action.payload.hierarchyLevelId.toString().toLowerCase() !==
				state.hierarchyLevelId.toString().toLowerCase()
			) {
				return state;
			}
			return {
				...state,
				currentlyWith: action.payload.currentlyWith,
				lastCalculated: action.payload.lastCalculationDate,
				reviewedBy: '',
			};
		},
		setTemporaryFailedCalculationNotificationStatusWidgetState: (
			state,
			action: PayloadAction<CalculationFailedNotificationPayload>,
		) => {
			if (
				action.payload.hierarchyLevelId.toString().toLowerCase() !==
				state.hierarchyLevelId.toString().toLowerCase()
			) {
				return state;
			}
			return {
				...state,
				currentlyWith: action.payload.currentlyWith,
				reviewedBy: '',
			};
		},
		setStatusUpdateNotificationStatusWidgetState: (
			state,
			action: PayloadAction<StatusWidgetMessage>,
		) => {
			if (
				action.payload.groupId.toLowerCase() !==
				state.hierarchyLevelId.toString().toLowerCase()
			) {
				return state;
			}
			return {
				...state,
				currentlyWith: action.payload.assignedTo,
				reviewedBy: action.payload.reviewedBy,
				isFinalized: action.payload.status === Status.Done,
				status: action.payload.status
			};
		},
	},
});

export const {
	setDetail,
	resetDetail,
	setTemporaryImportNotificationStatusWidgetState,
	setTemporarySuccessfulCalculationNotificationStatusWidgetState,
	setTemporaryFailedCalculationNotificationStatusWidgetState,
	setStatusUpdateNotificationStatusWidgetState
} = statusWidgetSlice.actions;
export default statusWidgetSlice.reducer;
