import { envProperties } from '../../core/Utils/EnvProperties';
import { FetchApiJsonParams } from './FetchApiJsonParams.types';
import { FetchApiParams } from './FetchApiParams.types';
import { FetchParams } from './FetchParams.types';
import { ServiceType } from './ServiceType.types';
import { getAccessToken } from '@rsmus/react-auth'

/**
 * Sends an authenticated fetch request to the provided url. Returns the
 * raw Response object.
 * @param url The Request URL.
 * @param method? The HTTP method of the request. Defaults to 'GET'.
 * @param headers (Optional) Additional HTTP headers.
 * @param body (Optional) The body of the HTTP request.
 * @returns The Response object.
 */
export const Fetch = async (params: FetchParams): Promise<Response> => {
    const { url, method, body } = params;

    var token = await getBearerToken().then((at) => at);
    params.headers = {
        ...params.headers,
        'Authorization': token
    }
    const config = {
        method: method ?? 'GET',
        headers: params.headers,
        body: body,
    };

    return window.fetch(url, config).then((response) => {
        return response;
    }).catch((error) => {
        throw error;
    });
};

/**
 * Sends an authenticated fetch request to the PCSAllocate API. Returns the
 * raw Response object.
 * @param endpoint The API endpoint.
 * @param method The HTTP method of the request.
 * @param headers (Optional) Additional HTTP headers.
 * @param body (Optional) The body of the HTTP request.
 * @returns The Response object.
 */
export const FetchApi = async ({
    endpoint,
    method,
    headers,
    body,
    apiType,
    apiVersion = 1
}: FetchApiParams): Promise<Response> => {
    let baseUrl: string = '';
    let version: string = `v${apiVersion}`;
    switch (apiType)
    {
        case ServiceType.Enrichment:
            baseUrl = `${envProperties.EnrichmentApiUrl}${version}`;
            break;
        case ServiceType.Import:
            baseUrl = `${envProperties.ImportApiUrl}${version}`;
            break;
        case ServiceType.Calculation:
            baseUrl = `${envProperties.CalculationApiUrl}${version}`;
            break;
        case  ServiceType.StatusWidget:
            baseUrl = `${envProperties.StatusWidgetApiUrl}${version}`;
            break;
        case  ServiceType.Common:
            baseUrl = `${envProperties.CommonApiUrl}${version}`;
            break;
    }
    const url = `${baseUrl}/${endpoint}`;

    return Fetch({ url, method, headers, body });
};

/**
 * Sends an authenticated fetch request to the PCSAllocate API with a
 * 'Content-Type' header of 'application/json'. Returns the json response on
 * an 'ok' result, otherwise returns the 'emptyResult' parameter, or an
 * empty JSON object.
 * @param endpoint The API endpoint.
 * @param method The HTTP method of the request.
 * @param headers (Optional) Additional HTTP headers.
 * @param body (Optional) The body of the HTTP request.
 * @param emptyReturn (Optional) The return value for an unsuccessful request. Defaults to an empty json object.
 * @param onOK (Optional) Callback function fired on successful request.
 * @param onError (Optional) Callback function fired on unsuccessful response.
 * @returns On a successful request, the response json. On an unsuccessful request, the emptyReturn value (if provided) or an empty json object.
 */
export const FetchApiJson = async (params: FetchApiJsonParams): Promise<unknown> => {
    try
    {
        params.headers = {
            ...params.headers,
            'Content-Type': 'application/json',
        };

        const result = await FetchApi(params);

        if (result.ok)
        {
            try
            {
                const json = await result.json();

                if (params.onOK)
                {
                    params.onOK(json);
                }

                return json as unknown;
            } catch
            {
                if (params.onOK)
                {
                    params.onOK(params.emptyReturn);
                }

                return params.emptyReturn;
            }
        }
        else
        {
            if (params.onError)
            {
                params.onError(result);

                console.error(`Fetching '${params.endpoint}' failed. Status code: ${result.status}`);
            }

            return params.emptyReturn ?? {};
        }
    } catch (e)
    {
        throw e;
    }
};

/* Gets the bearer token from the store */
async function getBearerToken(): Promise<string> {
    return getAccessToken().then((token) => 'Bearer ' + token);
}