export enum Messages {
	UNABLE_TO_GET_DATASET_DETAILS = 'Unable to get dataset details.',
	UNABLE_TO_RUN_CALCULATION = 'Unable to initiate calculation.',
	UPDATE_REFERENCE_TABLE = 'Update Reference tables',
	UPLOAD_REFERENCE_TABLE = 'Upload Reference tables',
	UPDATE = 'Update',
	CREATE = 'Create',
	START_CALCULATION = 'Run Calculation',
	ERROR_IN_DATASET_CALCULATION = 'Errors exist in dataset. See diagnostics in the Inputs tab.',
	UPLOAD_CONFIRMATION_TEXT = ' is uploaded. Do you want to upload again?',
	PLEASE_CONTACT_ENGAGEMENT_TEAM = 'Please contact the engagement team.',
	VALID_LIST_NAME_REQUIRED = 'Please upload the file with a valid list name',
	VALID_ASSET_OF_INTEREST_MESSAGE_REQUIRED = 'Please enter a message.',
	VALID_ASSET_OF_INTEREST_ASSETIDORUNDERLYINGID_REQUIRED = 'Please select the AssetID or the UnderlyingID option.',
	REFERENCE_TABLE_IS_INPROGRESS = 'Reference table update is in progress.',
	RUN_CALCULATION = 'Initiate calculation for ',
	RUN_CALCULATION_CONTENT_TEXT = 'The most recent calculation for this dataset took ',
	RUN_CALCULATION_CONFIRM_TEXT = 'YES, CALCULATE',
	CANCEL = 'CANCEL',
	CALCULATIONS_INITIATED = 'Calculation initiated.',
	CALCULATIONS_INITIATION = 'Calculation initiated successfully.',
	CALCULATIONS_SUCCEEDED = 'Calculation succeeded. Reports are ready for download',
	UPDATE_RULESETS = 'Update Rulesets',
	UPLOAD_RULESETS = 'Upload Rulesets',
	UPDATE_TEMPLATES = 'Update Empty Data Template',
	UPLOAD_TEMPLATES = 'Upload Empty Data Template',
	RULESETS_UPDATED_SUCCESSFULLY = 'Ruleset updated successfully',
	TEMPLATES_UPDATED_SUCCESSFULLY = 'Data Template updated successfully',
	UPDATE_ASSET_WAREHOUSE = 'Update Asset Warehouse',
	UPLOAD_ASSET_WAREHOUSE = 'Upload Asset Warehouse',
	ASSET_WAREHOUSE_UPDATED_SUCCESSFULLY = 'Asset Warehouse updated successfully',
	UPLOAD_IN_PROGRESS_ERROR_MESSAGE = 'An upload is in progress already. Please try again once it is completed.',
	FILE_IMPORT_IN_PROGRESS_MESSAGE = 'File import in progress.',
	UPLOADED_SUCCESSFULLY = 'Uploaded successfully.',
	BLOCK_CALC_RUN_NO_DATASET = 'No dataset available, cannot initiate calculation.',
	BLOCK_CALC_RUN_IMPORT_IN_PROGRESS = 'File import is in progress. Please wait for completion to initiate calculation.',
	BLOCK_CALC_RUN_IMPORT_FAILED = 'File import failed, cannot initiate calculation.',
	BLOCK_CALC_RUN_IN_PROGRESS = 'Previous calculation is in progress. Please wait for completion to initiate a new run.',
	UNABLE_TO_GET_MOST_RECENT_CALC = 'Unable to get most recent calculation run details.',
	POPULATED_TEMPLATE_FILE_DOWNLOAD_FAILED = 'File download failed. Please try after sometime.',
	DATA_TEMPLATE_DOWNLOAD_FAILED = 'Data template download failed',
	DATA_TEMPLATE_DOWNLOAD_SUCCESS = 'Data template downloaded successfully',
	DOWNLOAD_OUTPUT_REPORTS_SUCCESS = 'Report downloaded successfully',
	DOWNLOAD_OUTPUT_REPORTS_FAILED = 'Report download failed',
	DOWNLOAD_USER_GUIDE_SUCCESS = 'User Guide downloaded Successfully',
	DOWNLOAD_USER_GUIDE_FAILED = 'User Guide download Failed',
	DOWNLOAD_CALCULATION_ERROR_SUCCESS = 'Calculation error successfully downloaded',
	DOWNLOAD_CALCULATION_ERROR_FAILED = 'Calculation error failed to download',

	FILE_IMPORT_IN_PROGRESS_ERROR_MESSAGE = 'File import in progress. Try downloading once the import is completed',
	POPULATED_DATA_TEMPLATE_NO_IMPORT_FILE_MESSAGE = 'Import the input file and try downloading again.',

	//dividend
	UPDATE_DIVIDEND_WAREHOUSE = 'Update Dividend Warehouse',
	UPLOAD_DIVIDEND_WAREHOUSE = 'Upload Dividend Warehouse',
	DIVIDEND_WAREHOUSE_UPDATED_SUCCESSFULLY = 'Dividend Warehouse updated successfully',

	EXCEPTION_FOR_UPLOAD = 'An error occurred while uploading.',
	EXCEPTION_FOR_UPDATE = 'An error occurred while updating.',
	EXCEPTION_FOR_DOWNLOAD = 'An error occurred while downloading.',
	EXCEPTION_FOR_FETCH = 'An error occurred while fetching data.',
	EXCEPTION_FOR_FETCH_MOST_RECENT_CALCULATION = 'An error occurred while fetching most recent calculation.',

	//Dashboard
	EXCEPTION_FOR_FETCHING_CLIENT = 'An error occurred while fetching client data.',
	EXCEPTION_FOR_FETCHING_ENGAGEMENT = 'An error occurred while fetching engagement data.',
	EXCEPTION_FOR_FETCHING_JOB = 'An error occurred while fetching job data.',
	EXCEPTION_FOR_FETCHING_ENTITY = 'An error occurred while fetching entity data.',
	EXCEPTION_FOR_FETCHING_PROJECT = 'An error occurred while fetching project data.',
	EXCEPTION_FOR_FETCHING_AUTHORIZATION = 'An error occurred while fetching authorization data.',
	EXCEPTION_FOR_FETCING_CHART_DATA = 'Error occured while fetching chart data',
	WASHSALES_TOOLTIP = 'Please select Wash Sales setting to enable this option.',
	DIVIDEND_TOOLTIP = 'Please select one of the Dividend Settings to enable this option.',

	// Assets of Interest
	EXCEPTION_FOR_FETCHING_AOI_OUTPUT = 'An error occurred while fetching assets of interest output data.',
	AOI_OUTPUT_DATA_NOT_FOUND = 'Assets of interest output data not found.',
	UPLOAD_FILE_SUCCESSFUL = 'File upload successful',
	UPLOAD_FILE_FAILED = 'File upload failed',
	DOWNLOAD_FILE_FAILED = 'File download failed',

	IMPORT_SUCCESSFUL = 'Import successful',
	IMPORT_SUCCESS_WITH_WARNING = 'Import successful. There are Diagnostic warnings in your file. Please check the Inputs Tab to view.',
	IMPORT_FAILED = 'Import failed',
	IMPORT_DIAGNOSTICS_ERROR = 'Import completed. The input file has errors and cannot calculate. Please view the Diagnostics screen for more details.',

	ASSET_WAREHOUSE_UPDATE_FAILED = 'Asset Warehouse update failed',
	ASSET_WAREHOUSE_UPLOAD_FAILED = 'Asset Warehouse upload failed',
	ASSET_WAREHOUSE_DELETED_SUCCESSFULLY = 'Asset Warehouse deleted successfully',
	ASSET_WAREHOUSE_DELETE_FAILED = 'Asset Warehouse delete failed',
	ASSET_WAREHOUSE_DOWNLOAD_SUCCESS = 'Asset Warehouse downloaded successfully',
	ASSET_WAREHOUSE_DOWNLOAD_FAILURE = 'Asset Warehouse download failed',

	DIVIDEND_WAREHOUSE_UPDATE_FAILED = 'Dividend Warehouse update failed',
	DIVIDEND_WAREHOUSE_UPLOAD_FAILED = 'Dividend Warehouse upload failed',
	DIVIDEND_WAREHOUSE_DELETED_SUCCESSFULLY = 'Dividend Warehouse deleted successfully',
	DIVIDEND_WAREHOUSE_DELETE_FAILED = 'Dividend Warehouse delete failed',
	DIVIDEND_WAREHOUSE_DOWNLOAD_SUCCESS = 'Dividend Warehouse downloaded successfully',
	DIVIDEND_WAREHOUSE_DOWNLOAD_FAILURE = 'Dividend Warehouse download failed',

	ASSETS_OF_INTEREST_UPDATED_SUCCESSFULLY = 'Assets of Interest updated successfully',
	ASSETS_OF_INTEREST_UPDATE_FAILED = 'Assets of Interest update failed',
	ASSETS_OF_INTEREST_UPLOADED_SUCCESSFULLY = 'Assets of Interest uploaded successfully',
	ASSETS_OF_INTEREST_UPLOAD_FAILED = 'Assets of Interest upload failed',
	ASSETS_OF_INTEREST_DELETED_SUCCESSFULLY = 'Assets of Interest deleted successfully',
	ASSETS_OF_INTEREST_DELETE_FAILED = 'Assets of Interest delete failed',
	ASSETS_OF_INTEREST_DOWNLOAD_SUCCESS = 'Assets of Interest downloaded successfully',
	ASSETS_OF_INTEREST_DOWNLOAD_FAILURE = 'Assets of Interest download failed',

	RULESET_UPDATED_SUCCESSFULLY = 'Ruleset updated successfully',
	RULESET_UPDATE_FAILED = 'Ruleset update failed',
	TEMPLATE_UPDATE_FAILED = 'Template update failed',
	RULESET_UPLOAD_FAILED = 'Ruleset upload failed',
	RULESET_DELETED_SUCCESSFULLY = 'Ruleset deleted successfully',
	RULESET_DELETE_FAILED = 'Ruleset delete failed',
	RULESET_DOWNLOAD_SUCCESS = 'Ruleset downloaded successfully',
	RULESET_DOWNLOAD_FAILURE = 'Ruleset download failed',

	REFERENCE_TABLE_IS_UPDATING = 'Please wait while the file gets updated',
	REFERENCE_TABLE_UPDATE_SUCCESS = 'Reference Table update successful',
	REFERENCE_TABLE_UPDATE_FAILED = 'Reference Table update failed',
	REFERENCE_TABLE_UPLOAD_FAILED = 'Reference Table upload failed',
	REFERENCE_TABLE_DELETED_SUCCESSFULLY = 'Reference Table deleted successfully',
	REFERENCE_TABLE_DELETE_FAILED = 'Reference Table delete failed',
	REFERENCE_TABLE_DOWNLOAD_SUCCESS = 'Reference Table downloaded successfully',
	REFERENCE_TABLE_DOWNLOAD_FAILURE = 'Reference Table download failed',

	SETTINGS_SAVED_SUCCESSFULLY = 'Settings saved successfully',
	SETTINGS_SAVE_FAILED = 'Settings failed to save',
	SETTINGS_NOT_FOUND_FOR_CALCULATION = 'No settings found for the current calculation.',
	SETTINGS_NOT_FOUND_FOR_CALCULATION_CONTEXT = 'No settings found for the current calculation. Run calculation with default settings?',
	CALCULATIONS_FAILED = 'Calculation failed',
	CALCULATIONS_IN_PROGRESS = 'Calculation in progress',
	INPUT_DOWNLOAD_FAILURE = 'Input file failed to download',
	INPUT_DOWNLOAD_SUCCESS = 'Input file downloaded successfully',
	REPORT_DOWNLOAD_FAILED = 'Report download failed',

	//Status Widget
	ERROR_FETCHING_EVENTS = 'Error fetching events',
	ERROR_ADDING_EVENT = 'Error adding event',
	UNABLE_TO_GET_STATUS_WIDGET_USER_LIST = 'Unable to get engagement users',

	//Favorite Job
	FAVORITE_JOB = 'The job is added to the favorites list',
	UN_FAVORITE_JOB = 'The job is removed from the favorites list',
}
